const initState = {
}

const masterReducer = (state = initState, action) => {
    switch( action.type ){

    }

    return state;
}

export default masterReducer;