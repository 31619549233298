import { BASE_API_URL } from '../../constants/api-config';
import { Helper } from '../../helpers/helper';


class HttpRequest {

    constructor() {
        this.headerWithToken = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+Helper.getToken()
        };

        this.headerWithTokenForFile = {
            'Authorization': 'Bearer '+Helper.getToken()
        }

        this.header = {
            'Content-Type': 'application/json',
        };
    }

    static headers() {
        return {};
    }

    async getData(url, withPagination = false) {
        try {
            const response = await fetch(`${BASE_API_URL}${url}`, {
                headers: this.headerWithToken,
                method: 'GET'
            });

            const result = await response.json();

            if (result.status===200) {
                return {
                    error: false,
                    data: withPagination ? result : result.data,
                    extra: result.extra ? result.extra:{}
                };
            }

            if (result.status===422) {
                return {
                    error: true,
                    message: result.error
                };
            }

            if (!result.auth && result.token_expired) {
                this.clearLocalStroage();
                return {
                    error: true,
                    message: result.message,
                    redirect: true
                }
            }

            if (result === 'Invalid Token') {
                this.clearLocalStroage();
                return {
                    error: true,
                    //message: result.status_text,
                    redirect: true
                }
            }

            throw new Error('Some thing went wrong..');

        } catch (error) {
            return {
                error: true,
                data: null,
                message: error
            }
        }
    }

    async getWithFilterData(url, params, withPagination = false) {
        try {
            const response = await fetch(`${BASE_API_URL}${url}?${new URLSearchParams(params)}`, {
                headers: this.headerWithToken,
                method: 'GET'
            });

            const result = await response.json();

            if (result.status) {
                return {
                    error: false,
                    data: withPagination ? result : result.data,
                    extra: result.extra ? result.extra:{}
                };
            }

            if (!result.auth && result.token_expired) {
                this.clearLocalStroage();
                return {
                    error: true,
                    message: result.message,
                    redirect: true
                }
            }

            throw new Error('Some thing went wrong..');

        } catch (error) {
            return {
                error: true,
                data: null,
                message: error
            }
        }
    }

    async postData(url, postData, login = false) {
        try {
            //Helper.appLog( postData );
            const response = await fetch(`${BASE_API_URL}${url}`, {
                headers: login ? this.header : this.headerWithToken,
                method: 'POST',
                body: JSON.stringify(postData)
            });

            const result = await response.json();
            if (result.status===200) {
                return {
                    error: false,
                    data: result
                }
            }

            if (!result.auth && result.token_expired) {
                this.clearLocalStroage();
                return {
                    error: true,
                    message: result.status_text,
                    redirect: true
                }
            }

            if( result.status===401 ){
                return {
                    error: true,
                    message: result.status_text
                }
            }

            if( result.status===422 ){
                return {
                    error: true,
                    message: result.error
                }
            }


            return {
                error: true,
                data: null,
                message: result.status_text
            }


        } catch (error) {
            //Helper.appLog(error.message);
            return {
                error: true,
                data: null,
                message: 'Some thing went wrong.'
            }
        }
    }

    async postDataWithFiles(url, postData, method) {
        try {
            const result = await (await fetch(`${BASE_API_URL}${url}`, {
                headers: this.headerWithTokenForFile,
                method: method || 'POST',
                body: postData
            })).json();

            if(result.status===200){
                return {
                    error: false,
                    data: result.data
                }
            }

            if( result.status===401 ){
                return {
                    error: true,
                    message: result.status_text
                }
            }

            if( result.status===422 ){
                return {
                    error: true,
                    message: result.error
                }
            }


            return {
                error: true,
                data: null,
                message: result.status_text
            }

        } catch (error) {
            Helper.appLog(error);
            return {
                error: true,
                data: null
            }
        }
    }

    async patchData(url, postData) {
        try {

            const response = await fetch(`${BASE_API_URL}${url}`, {
                headers: HttpRequest.headers,
                method: 'PATCH',
                body: JSON.stringify(postData)
            });

            return await response.json();
        } catch (error) {
            Helper.appLog(error);
        }
    }

    async deleteData(url) {
        try {
            const response = await fetch(`${BASE_API_URL}${url}`, {
                method: 'POST',
                headers: this.headerWithToken
            });
            const result = await response.json();
            if(result.status===200){
                return {
                    error: false,
                    data: null,
                    message: result.status_text
                }
            }
            
            return {
                error: true,
                data: null,
                message: result.status_text
            }

        } catch (error) {
            return {
                error: true,
                data: null,
                message: 'Some thing went wrong.'
            }
        }
    }

    clearLocalStroage(){
        localStorage.clear();
    }
}

export default HttpRequest;