import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { adjustSideBar } from "../store/actions/auth";
import Footer from "./Footer";
import SideBar from "./SideBar";
import TopBar from "./TopBar";

const DefaultStructure = (props) => {
  const { children, pageTitle } = props;
  const sideBarStatus = useSelector((state) => state.auth.sideBarStatus);
  const dispatch = useDispatch();

  const _updateSideBarStatus = (status) => {
    dispatch(adjustSideBar(status));
  };

  return (
    <>
    <TopBar title={pageTitle} />
    <SideBar />
    <div id="main">
      <div className="row">
        <div className="content-wrapper-before gradient-45deg-indigo-purple admn_instal"></div>
        {children}
      </div>
    </div>
    <Footer />
    </>
  );
};

export default DefaultStructure;
