export default {
    user: {
        model: "User",
        list: "user-list",
        create: "user-create",
        edit: "user-edit",
        delete: "user-delete"
    },
    role: {
        model: "Role",
        list: "role-list",
        create: "role-create",
        edit: "role-edit",
        delete: "role-delete"
    },
    hr: {
        model: "Hr",
        list: "hr-list",
        create: "hr-create",
        edit: "hr-edit",
        delete: "hr-delete"
    },
    zone: {
        model: "Zone",
        list: "zone-list",
        create: "zone-create",
        edit: "zone-edit",
        delete: "zone-delete"
    },
    centers: {
        model: "Centers",
        list: "center-list",
        create: "center-create",
        edit: "center-edit",
        delete: "center-delete"
    },
    department: {
        model: "Department",
        list: "department-list",
        create: "department-create",
        edit: "department-edit",
        delete: "department-delete"
    },
    boards: {
        model: "Boards",
        list: "boards-list",
        create: "boards-create",
        edit: "boards-edit",
        delete: "boards-delete"
    },
    tags: {
        model: "Tags",
        list: "tags-list",
        create: "tags-create",
        edit: "tags-edit",
        delete: "tags-delete"
    },
    class: {
        model: "Class",
        list: "class-list",
        create: "class-create",
        edit: "class-edit",
        delete: "class-delete"
    },
    section: {
        model: "Section",
        list: "section-list",
        create: "section-create",
        edit: "section-edit",
        delete: "section-delete"
    },
    team: {
        model: "Team",
        list: "team-list",
        create: "team-create",
        edit: "team-edit",
        delete: "team-delete"
    },
    scholarship: {
        model: "Scholarship",
        list: "scholarship-list",
        create: "scholarship-create",
        edit: "scholarship-edit",
        delete: "scholarship-delete"
    },
    fee: {
        model: "Fee",
        list: "fee-list",
        create: "fee-create",
        edit: "fee-edit",
        delete: "fee-delete"
    },
    course: {
        model: "Course",
        list: "course-list",
        create: "course-create",
        edit: "course-edit",
        delete: "course-delete"
    },
    coursefee: {
        model: "Coursefee",
        list: "coursefee-list",
        create: "coursefee-create",
        edit: "coursefee-edit",
        delete: "coursefee-delete"
    },
    coursetopic: {
        model: "Coursetopic",
        list: "coursetopic-list",
        create: "coursetopic-create",
        edit: "coursetopic-edit",
        delete: "coursetopic-delete"
    },
    coursesubtopic: {
        model: "Coursesubtopic",
        list: "coursesubtopic-list",
        create: "coursesubtopic-create",
        edit: "coursesubtopic-edit",
        delete: "coursesubtopic-delete"
    },
    subject: {
        model: "Subject",
        list: "subject-list",
        create: "subject-create",
        edit: "subject-edit",
        delete: "subject-delete"
    },
    sources: {
        model: "Sources",
        list: "sources-list",
        create: "sources-create",
        edit: "sources-edit",
        delete: "sources-delete"
    },
    leads: {
        model: "Leads",
        list: "leads-list",
        create: "leads-create",
        edit: "leads-edit",
        delete: "leads-delete"
    },
    admission: {
        model: "admission",
        list: "admission-list",
        create: "admission-create",
        edit: "admission-edit",
        delete: "admission-delete"
    },
    academic: {
        model: "academic",
        list: "academic-list",
        create: "academic-create",
        edit: "academic-edit",
        delete: "academic-delete"
    },
    store: {
        model: "Store",
        list: "store-list",
        create: "store-create",
        edit: "store-edit",
        delete: "store-delete"
    },
    announcement: {
        model: "Announcement",
        list: "announcement-list",
        create: "announcement-create",
        edit: "announcement-edit",
        delete: "announcement-delete"
    },
    holiday: {
        model: "Holiday",
        list: "holiday-list",
        create: "holiday-create",
        edit: "holiday-edit",
        delete: "holiday-delete"
    },
    leavemaster: {
        model: "Leavemaster",
        list: "leavemaster-list",
        create: "leavemaster-create",
        edit: "leavemaster-edit",
        delete: "leavemaster-delete"
    },
    leavetype: {
        model: "Leavetype",
        list: "leavetype-list",
        create: "leavetype-create",
        edit: "leavetype-edit",
        delete: "leavetype-delete"
    },
    counselling: {
        model: "counselling",
        list: "counselling-list",
        create: "counselling-create",
        edit: "counselling-edit",
        delete: "counselling-delete"
    },
    facultyAssign: {
        model: "facultyAssign",
        list: "faculty-assign-list",
        create: "faculty-assign-create",
        edit: "faculty-assign-edit",
        delete: "faculty-assign-delete"
    },
    additionalProduct: {
        model: "AdditionalProduct",
        list: "additional-product-list",
        create: "additional-product-create",
        edit: "additional-product-edit",
        delete: "additional-product-delete"
    }
}