import HttpRequest from './HttpRequest';

export const getAllRoles = async () => {
    try {

        const result = await (new HttpRequest()).getData(`manage-role`);

        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getAllPermission = async () => {
    try {

        const result = await (new HttpRequest()).getData(`manage-role/permissions`);

        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const createRole = async (data) => {
    try {

        const result = await (new HttpRequest()).postData(`manage-role`, data);

        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getRole = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`manage-role/${id}/edit`);

        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const updateRole = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`manage-role/${id}`, data);

        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const deleteRole = () => {

}

export const hasPermission = (module_name, name, user) => {
    if (!user || !user.roles) {
        return true;
    }
    for (let i = 0; i < user.roles.length; i++) {
        let item = user.roles[i];
        if (item.slug === "Superadmin") {
            return true
        }

        if (!item.permission_array) {
            return false;
        }

        let permission_array_map = item.permission_array.map(iter => {

            return iter.data.map(item => {
                return {
                    name: item.name,
                    label: item.label,
                    isChecked: item.isChecked ? item.isChecked : false,
                    key: iter.key
                }
            });
        })
        let permission_array_map_item = permission_array_map.reduce(function (prev, next) {
            return prev.concat(next);
        })
        for (let i = 0; i < permission_array_map_item.length; i++) {
            if (permission_array_map_item[i].name === name
                && module_name === permission_array_map_item[i].key
                && permission_array_map_item[i].isChecked) {
                return true;
            }
        }

    }
    return false;
}