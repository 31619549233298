import React from 'react';
import {Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {RouteNames} from "../constants/RouteNames";
import {Helper} from "../helpers/helper";

export default function ProtectedRoute({component: Component}) {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    Helper.appLog(isAuthenticated);

    /*    return (
            <Route
                {...rest}
                render={(props) =>
                    isAuthenticated ?
                        <Component {...props}/>
                        :
                        <Navigate to={RouteNames.login} />
                }
            />
        )*/

    return isAuthenticated ?
        <Component />
        :
        <Navigate to={RouteNames.login}/>
}