import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {
    getCourseDetailsByAdmissionAndCourseId,
    getCourseFeeSearchByCourseId,
    getScholarship,
    getSubjects,
    getTags,
    getcourseList,
    updateAddmissionCourseDetails
} from '../../../store/actions/master';
import {useDispatch} from 'react-redux';
import moment from 'moment';
import Item from './Item';
import {Link} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {base} from '../../../constants/RouteNames';
import {toggleLoader} from '../../../store/actions/auth';

function CourseSectionById() {
    const params = useParams();
    let navigate = useNavigate()
    const dispatch = useDispatch();
    const [courseData, setCourseData] = useState({})
    const [courseFeesList, setCourseFeesList] = useState([])
    const [courseChip, setCourseChip] = useState([{coursename: ''}]);
    const [courseList, setCourseList] = useState([])
    const [scholarShips, setScholarships] = useState([])
    const [sessiondata, setSessionData] = useState({});
    const [subjects, setSubjects] = useState([]);
    const [tags, setTags] = useState([])
    const [tagsName, setTagsName] = useState([])
    const [subjectchange, setSubjectchange] = useState(0)
    const [subjectName, setSubjectName] = React.useState([]);
    const [courseSubjects, setCourseSubjects] = useState([]);
    const [sessionFrom, setSessionFrom] = useState()
    const [sessionTo, setSessionTo] = useState()
    const [wavesAmount, setWavesAmount] = useState()
    const [remark, setRemark] = useState()
    const [isUpdateCourseFees, setIsUpdateCourseFees] = useState(false)
    const [numberInstallment, setNumberInstallment] = useState()
    const [studentCourseDetails, setStudentCourseDetails] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState();
    const [loader, setLoader] = useState(true);
    const HeadingTitle = [
        'Fee Type',
        'Scholarship Applicable',
        'Course Fees (Rs.)',
        'Select Scholarship',
        'Selected Scholarship',
        'Received Scholarship (Rs.)',
        'Amount (Rs.)'
    ];


    useEffect(() => {

        getScholarship({limit: 200}).then(res => {
            setScholarships(res.data)
        });

        getSubjects({limit: 200}).then(resp => {
            setSubjects(resp.data);
        })
        getTags({limit: 200}).then(resp => {
            setTags(resp.data);
        })
        getcourseList({limit: 200}).then(resp => {
            setCourseList(resp.data);
        })
        getCourseDetailsByAdmissionAndCourseId(params.admission_id, params.course_id).then(resp => {
            // Helper.appLog('====================================');
            // Helper.appLog(resp);
            // Helper.appLog('====================================');
            if (resp.data.length > 0) {
                let sub = (resp.data[0]?.subject_ids ?? '').split(',');
                sub = sub.filter(su => su != null && parseInt(su) > 0);
                setStudentCourseDetails(resp.data[0]);
                setSubjectName(sub.map(s => parseInt(s)))
                // setSubjects(sub)
                // for(let i = 0; i <sub.length; i++){
                //     handleSubjectChange(sub[i])
                // }
                setCourseData({...courseData, subjects: sub})
                setWavesAmount(resp.data[0].waver_amount)
                setRemark(resp.data[0].remarks)
                setSessionFrom(resp.data[0].from_session)
                setSessionTo(resp.data[0].to_session)
                handleAddCourse(resp.data[0].course_id)
            } else {
                setLoader(false)
            }

        });

    }, [])

    // Helper.appLog(subjectName);
    // Helper.appLog('====================================');

    const handleTagChange = (event) => {
        const {target: {value}} = event;
        setTagsName(typeof value === 'intiger' ? value.split(',') : value,);
        var getselectcourses = [...courseFeesList];
        getselectcourses.push({
            tag_ids: typeof value === 'intiger' ? value.split(',') : value,
        });

        getcourseList({limit: 500, tag_ids: typeof value === 'intiger' ? value.split(',') : value}).then((res) => {

            setCourseList(res.data)
        })

    };

    const getCourseName = (id) => {
        var name = '';
        for (var i = 0; i < courseList.length; i++) {
            if (id === courseList[i].id) {
                name = courseList[i].course_name;
            }
        }
        return name
    }
    const finalCourseFeeListChanger = (allCourseFees) => {

        setCourseFeesList((prev) => {
            allCourseFees = allCourseFees.map(item => {
                item.total = item.data.reduce(function (accumulator, currentValue) {
                    return accumulator + currentValue.fees_amount;
                }, 0);
                item.percent_amount = item.data.reduce(function (accumulator, currentValue) {
                    return accumulator + (currentValue.percent_amount ? currentValue.percent_amount : 0);
                }, 0);
                item.amount = item.total - (item.percent_amount ? item.percent_amount : 0)
                return item
            })
            return allCourseFees
        })
        setLoader(false)
    }


    const getCourseFeeByCourseIdId = (id) => {
        getCourseFeeSearchByCourseId(id).then(res => {
            var allCourseFees = [];
            let hasCourse = allCourseFees.find(res => res.course_id == id);
            if (res.data.length && !hasCourse) {
                allCourseFees.push({
                    course_id: id,
                    course_name: getCourseName(id),
                    data: res.data.map(item => {
                        item.fees_amount = parseFloat(item.fees_amount)
                        return item
                    }),
                    total: 0
                })
            }
            //allCourseFees.push(sessiondata);
            finalCourseFeeListChanger(allCourseFees)

        })
    }
    const handleRemoveCourse = (index, course_id) => {
        const values = [...courseChip];
        values.splice(index, 1)
        setCourseChip(values);
        var allCourseFeesList = [...courseFeesList]
        let courseIndex = allCourseFeesList.findIndex(item => item.course_id == course_id)
        if (courseIndex > -1) {
            allCourseFeesList.splice(courseIndex, 1)
            finalCourseFeeListChanger(allCourseFeesList)

        }

    }


    const handleAddCourse = (e) => {
        setSelectedCourse(e)
        setCourseData({})
        setCourseList([])
        // setCourseChip([])
        setCourseFeesList([])
        let hasCourseChip = courseChip.find(data => data.coursename == e)
        if (!hasCourseChip) {
            setCourseChip([{coursename: e}])
            getCourseFeeByCourseIdId(e)
        }
    }

    const handleSubjectChange = (event) => {
        const {target: {value},} = event;
        setSubjectName(
            typeof value === 'intiger' ? value.split(',') : value,
        );

        var getselectcourses = [...courseFeesList];
        // Helper.appLog('---------> ', getselectcourses);
        getselectcourses.push({
            subject_ids: typeof value === 'intiger' ? value.split(',') : value,
        });
        setCourseSubjects(value)
        setSubjectchange(1)
    };

    const handleSessionChange = (sessiondata) => {
        if (sessiondata.target.name === 'session_from') {
            setSessionFrom(sessiondata.target.value)
        } else {
            setSessionTo(sessiondata.target.value)
        }


    }

    const handleCourseData = (allCourseData) => {
        let courseItem = {}
        courseItem.grand_total = allCourseData.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.total;
        }, 0);
        courseItem.grand_percent_amount = allCourseData.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.percent_amount;
        }, 0);
        courseItem.grand_amount = allCourseData.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.amount;
        }, 0);
        courseItem.data = allCourseData.map((item) => {
            return {
                course_id: item.course_id,
                total: item.total,
                percent_amount: item.percent_amount,
                amount: item.amount,

                data: item.data.map(fee => {
                    return {
                        id: fee.id,
                        course_id: fee.course_id,
                        fee_id: fee.fee_id,
                        fees_amount: fee.fees_amount,
                        fee_details: {
                            id: fee.fee_details.id,
                            scholarchip_applicable: fee.fee_details.scholarchip_applicable
                        },
                        percent_amount: fee.percent_amount,
                        amount: fee.amount
                    }
                })
            }
        });
        // setCourseData({...courseItem,subjects:courseSubjects[1]})
        setCourseData({...courseItem, subjects: courseSubjects})
    }

    useEffect(() => {
        getcourseList({limit: 500}).then((res) => {
            setCourseList(res.data)
        })

        handleCourseData(courseFeesList)
    }, [courseFeesList, courseSubjects])


    // Helper.appLog('===============gogogo==============');
    // Helper.appLog({ courseData, courseFeesList });
    // Helper.appLog('====================================');
    // Helper.appLog('===========hhhhhhhhhhh================');
    // Helper.appLog(courseData);
    // Helper.appLog('====================================');
    const handelUpdateForm = async () => {
        // Helper.appLog('===999================');
        // Helper.appLog(courseData);
        // Helper.appLog('====================================');
        if (courseData.data.length === 0) {
            toast.error('Please select a course')
        } else if (!sessionFrom) {
            toast.error('Please select Session From')
        } else if (!sessionTo) {
            toast.error('Please select Session To')
        } else if (subjectName.length === 0) {
            toast.error('Please select Subject')
        } else if (isUpdateCourseFees && !numberInstallment) {
            toast.error('Please select Installment')
        } else {
            let courseUpdateData = {
                isupdate_course_fees: isUpdateCourseFees ? 1 : 0,
                existing_course_id: params.course_id,
                course_id: courseData.data[0].course_id,
                from_session: sessionFrom,
                to_session: sessionTo,
                waver_amount: wavesAmount,
                comitted_fees: courseData.grand_amount,
                course_fees: courseData.grand_total,
                total_installment: numberInstallment,
                remark: remark,
                total_paid: courseData.total_paid,
                subjects: subjectName
            };
            console.log(courseUpdateData, `update data!`);
            const resp = await updateAddmissionCourseDetails(params.admission_id, courseUpdateData);

            if (!resp.error) {
                toast.success('Data updated successfully')
                //navigate(`${base}/admin/admission/details/${params.admission_id}`)


            } else {
                toast.success('Something went wrong')
            }
        }
    }

    if (loader) {
        return (
            <>
                <h4>Loading...</h4>
            </>
        )
    } else if (studentCourseDetails !== null) {
        return (
            <>
                <div className="row">
                    <div>
                        <TableContainer>
                            <Table sx={{minWidth: 700}} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Comitted Fees</TableCell>
                                        <TableCell align="center">Course Fees</TableCell>
                                        <TableCell align="center">Total Paid</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableCell align="center">{studentCourseDetails?.comitted_fees}</TableCell>
                                    <TableCell align="center">{studentCourseDetails?.course_fees}</TableCell>
                                    <TableCell align="center">{studentCourseDetails?.total_paid}</TableCell>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="row">
                        <div className="input-field col m12 s12">
                            <FormGroup>
                                <FormControlLabel required control={<Checkbox checked={isUpdateCourseFees}
                                                                              onChange={(e) => setIsUpdateCourseFees(!isUpdateCourseFees)}/>}
                                                  label="Do you want to overight course fees"/>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col m6 s12">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <FormControl sx={{width: '100%'}}>
                                    <InputLabel id="demo-multiple-checkbox-label">Tags</InputLabel>
                                    <Select
                                        labelId="demo-multiple-checkbox-label"
                                        id="demo-multiple-checkbox"
                                        multiple
                                        value={tagsName}
                                        onChange={handleTagChange}
                                        input={<OutlinedInput label="Tags"/>}
                                        renderValue={(selected) => tags.filter((gtag) => selected.indexOf(gtag.id) != -1).map(sname => sname.tag_name).join(', ')}
                                        style={{width: '100%'}}
                                        className="selectClass borderrediuszero"
                                    >
                                        {tags && tags.length > 0 && tags.map((tag, index) => (
                                            <MenuItem key={tag.id} value={tag.id}>
                                                <Checkbox checked={tagsName.indexOf(tag.id) > -1}/>
                                                <ListItemText primary={tag.tag_name}/>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="input-field col m6 s12">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {/* {selectedCourse&&<FormControl sx={{ width: '100%' }} >
                                <InputLabel id="label-course">  Course</InputLabel>
                                <Select
                                    defaultValue={0}
                                    labelId="label-course"
                                    style={{ opacity: '0px' }}
                                    name="center_id"
                                    className="selectClass"
                                    value={selectedCourse}
                                    onChange={(e) => handleAddCourse(e.target.value)}
                                >
                                    {courseList.length > 0 ? (courseList.map(buser => {
                                        if (buser.course_name != '') {
                                            return (
                                                <MenuItem value={buser.id} name={buser.course_name} key={buser.id}>
                                                    {` ${buser.department_details.department_name} -> ${buser.class_details.class_name} -> ${buser.course_name}`}
                                                </MenuItem>
                                            )
                                        }
                                    })) : (<MenuItem selected={false} disabled>
                                        Please select at least one tag
                                    </MenuItem>)}
                                </Select>
                            </FormControl>} */}
                                <FormControl sx={{width: '100%'}}>
                                    <InputLabel id="label-course"> Course</InputLabel>
                                    <Select
                                        defaultValue={0}
                                        labelId="label-course"
                                        style={{opacity: '0px'}}
                                        name="center_id"
                                        className="selectClass"
                                        value={selectedCourse}
                                        onChange={(e) => handleAddCourse(e.target.value)}
                                    >
                                        {courseList.length > 0 ? (courseList.map(buser => {
                                            if (buser.course_name != '') {
                                                return (
                                                    <MenuItem value={buser.id} name={buser.course_name} key={buser.id}>
                                                        {` ${buser.department_details.department_name} -> ${buser.class_details.class_name} -> ${buser.course_name}`}
                                                    </MenuItem>
                                                )
                                            }
                                        })) : (<MenuItem selected={false} disabled>
                                            Please select at least one tag
                                        </MenuItem>)}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="input-field col m3 s12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-checkbox-label">Session From</InputLabel>
                                <Select
                                    id="session_from"
                                    name="session_from"
                                    label="Session From"
                                    variant="standard"
                                    value={sessionFrom}
                                    onChange={(e) => handleSessionChange(e)}
                                    className="selectClass error-border"
                                    style={{width: '100%'}}
                                >
                                    <MenuItem disabled value={0}>
                                        Session From
                                    </MenuItem>
                                    {(() => {
                                        let minYearOption = [];
                                        for (let minyeari = 0; minyeari <= 1; minyeari++) {
                                            minYearOption.push(<MenuItem
                                                value={moment(new Date().setFullYear(new Date().getFullYear() + minyeari)).format('YYYY')}>{moment(new Date().setFullYear(new Date().getFullYear() + minyeari)).format('YYYY')}</MenuItem>);
                                        }
                                        return minYearOption;
                                    })()}
                                </Select>
                            </FormControl>


                        </div>
                        <div className="input-field col m3 s12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-checkbox-label">Session To</InputLabel>
                                <Select
                                    id="session_to"
                                    name="session_to"
                                    select
                                    label="Session To"
                                    variant="standard"
                                    value={sessionTo}
                                    onChange={(e) => handleSessionChange(e)}
                                    className="selectClass error-border"
                                    style={{width: '100%'}}
                                >
                                    <MenuItem disabled value={0}>
                                        Session To
                                    </MenuItem>
                                    {(() => {
                                        let yearOption = [];
                                        for (let yeari = 1; yeari <= 7; yeari++) {
                                            yearOption.push(<MenuItem
                                                value={moment(new Date().setFullYear(new Date().getFullYear() + yeari)).format('YYYY')}>{moment(new Date().setFullYear(new Date().getFullYear() + yeari)).format('YYYY')}</MenuItem>);
                                        }
                                        return yearOption;
                                    })()}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="input-field col m6 s12">
                            <FormControl fullWidth>
                                <InputLabel id="demo-multiple-checkbox-label">Subject</InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={subjectName}
                                    onChange={handleSubjectChange}
                                    input={<OutlinedInput label="Subject"/>}
                                    renderValue={(selected) => subjects.filter((gsubject) => selected.indexOf(gsubject.id) != -1).map(sname => sname.subject_name).join(', ')
                                    }
                                    style={{width: '100%'}}
                                    className="selectClass borderrediuszero"
                                >
                                    {subjects && subjects.length > 0 && subjects.map((subject, index) => (
                                        <MenuItem key={subject.id} value={subject.id}>
                                            <Checkbox checked={subjectName.indexOf(subject.id) > -1}/>
                                            <ListItemText primary={subject.subject_name}/>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row course-select" style={{'margin-left': '-15px'}}>
                        {courseChip.map((input, index) => {
                            if (input.coursename != '')

                                return (
                                    <div className="col">
                                        <div style={{width: '10px'}}></div>
                                        <span style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: 10,
                                            backgroundColor: '#fff',
                                            borderRadius: '25px',
                                            'box-shadow': '0 0 10px 1px rgb(0 0 0 / 20%)'
                                        }}>{getCourseName(input.coursename)}
                                            <i onClick={() => handleRemoveCourse(index, input.coursename)}
                                               style={{paddingLeft: 20, cursor: 'pointer', 'color': '#ff4c4c'}}
                                               className="material-icons">delete</i>
                                    </span>
                                        <div style={{width: '10px'}}></div>
                                    </div>
                                )
                        })}
                    </div>
                    {
                        courseFeesList.map((value, id) => {
                            return (
                                <>
                                    <div className="crsheading" key={id}>
                                        {value.course_name}
                                    </div>
                                    <Table key={value.course_id} listHeading={HeadingTitle}>
                                        {value.data.length > 0 ? value.data.map(courseFee => <Item key={courseFee.id}
                                                                                                   data={courseFee}
                                                                                                   scholarShips={scholarShips}
                                                                                                   courseFeesList={courseFeesList}
                                                                                                   finalCourseFeeListChanger={finalCourseFeeListChanger}/>)
                                            : <tr>
                                                <td colSpan={6} className="text-center">
                                                    <span className="name">No Data found</span>
                                                </td>
                                            </tr>
                                        }
                                        <tr>
                                            <td>Total</td>
                                            <td></td>
                                            <td><span className="email">{value.total}</span></td>
                                            <td></td>
                                            <td></td>
                                            <td><span className="email">{value.percent_amount}</span></td>
                                            <td><span className="email">{value.amount}</span></td>
                                        </tr>
                                    </Table>
                                </>
                            )
                        })
                    }
                    <div className="row">
                        <div className="input-field col s4">
                            <TextField
                                id="waver_amount"
                                name='waver_amount'
                                label="Waiver Amount (Rs.)"
                                type='number'
                                onChange={(e) => setWavesAmount(e.target.value)}
                                value={wavesAmount}
                                margin="normal"
                                variant="standard"
                                style={{width: '100%'}}
                            />
                        </div>
                        <div className="input-field col m8 s12">
                            <TextField
                                value={remark}
                                onChange={(e) => setRemark(e.target.value)}
                                id="outlined-basic"
                                label="Remarks"
                                variant="standard"
                                name="waver_remark"
                                style={{width: '100%'}}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="input-field col s4">
                            <FormControl fullWidth>
                                <InputLabel id="demo-selection">Number of Installment</InputLabel>
                                <Select
                                    // variant="standard"
                                    value={numberInstallment}
                                    onChange={(event) => setNumberInstallment(event.target.value)}
                                    // label="Payment Type"
                                    labelId="demo-selection"
                                    style={{opacity: '0px', width: '100%'}}
                                    name="payment_type"
                                    className="selectClass"
                                >
                                    <MenuItem disabled value={0}>
                                        Number of Installment
                                    </MenuItem>
                                    {(() => {
                                        let yearOption = [];
                                        for (let yeari = 0; yeari < 45; yeari++) {
                                            yearOption.push(<MenuItem value={yeari + 1}>{yeari + 1}</MenuItem>);
                                        }
                                        return yearOption;
                                    })()}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="input-field col m8 s12">

                        </div>
                    </div>
                    <Link to="#" className="btn custom right" onClick={() => handelUpdateForm()}>
                        <span style={{'marginRight': '10px'}}>Update</span>
                        <i className="material-icons">send</i>
                    </Link>

                </div>
            </>
        )
    } else {
        return (
            <>
                <h4>Course Details Not found...</h4>
            </>
        )
    }

}

export default CourseSectionById