import React, {useState, useEffect, useRef} from "react";
import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Helper} from '../helpers/helper';
import {hasPermission} from "../store/actions/rolesPermission"
import rolenames from "../constants/rolenames";

const SideBar = ({barStatus, onSideBarOpen}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const user = useSelector(state => state.auth.user)
    const mobileMenuRef = useRef()
    const hembergMenuRef = useRef()
    const [activeLink, setActiveLink] = useState('')
    const [activeLabel, setActiveLabel] = useState('')
    const [isMobileMenu, setIsMobileMenu] = useState(false)
    const [isHembergMenu, setIsHembergMenu] = useState(false)
    const labelNames = [{key: 'users', value: 'management'}];
    const _addActiveclassName = (link) => {
        return location.pathname === link ? 'active' : null;
    }

    const _isOpen = (paths) => {
        let openFlag = false;
        if (Array.isArray(paths) && paths.length > 0)
            paths.forEach(path => {
                if (_addActiveclassName(path) === 'active')
                    openFlag = true;
            });

        return openFlag;
    }

    useEffect(() => {
        if (window.innerWidth <= 768) {
            document.addEventListener("click", handleClickOutSide, true);
            //setIsMobileMenu(true)
        } else {
            setIsMobileMenu(true)
        }

        const locationArr = location.pathname.split('/')
        const urlName = locationArr.slice(-1).pop()
        const label = labelNames.find(obj => obj.key === urlName)
        if (label) {
            setActiveLabel(label.value)
        }
        setActiveLink(urlName)
        return () => {

        }
    }, [location.pathname])

    const handleActiveLabel = (labelName) => {
        if (activeLabel !== labelName) {
            setActiveLabel(labelName)
        } else {
            setActiveLabel('')
        }
    }

    Helper.appLog(activeLabel, 89988);

    const handleLink = (name) => {

        const linkName = name.split('/').slice(-1).pop()
        const locationArr = location.pathname.split('/')
        const urlName = locationArr.slice(-1).pop()
        setActiveLink(urlName)
        setActiveLink(linkName);

        if (urlName !== linkName) {
            window.history.pushState({}, document.title, "/" + name);
            window.location.reload();

            //history.replace('pathfinder-erp/' +name)
        }

    }

    const handleClickOutSide = (event) => {
        Helper.appLog(event.target, 88888);
        if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
            setIsMobileMenu(false)
        }
    }

    Helper.appLog(user);
    Helper.appLog("------------vvbbvv---------")
    Helper.appLog(rolenames)
    Helper.appLog('--------bbbvv--')
    return (
        <>
            {user && user.id > 0 ?
                <aside
                    className={`sidenav-main nav-collapsible sidenav-light sidenav-active-square ${isHembergMenu ? 'nav-collapsed' : 'nav-lock'}`}>
                    <div className="brand-sidebar">
                        <h1 className="logo-wrapper">
                            <Link className="brand-logo darken-1" to="#">
                                <img className="hide-on-med-and-down"
                                     src={require('../assets/images/logo/materialize-logo-color.png')}
                                     alt="Pathfinder ERP"/>
                                <img className="show-on-medium-and-down hide-on-med-and-up"
                                     src={require('../assets/images/logo/materialize-logo.png')}
                                     alt="materialize logo"/>
                            </Link>
                            <Link className="navbar-toggler" to="#" ref={hembergMenuRef}
                                  onClick={() => setIsHembergMenu(!isHembergMenu)}>
                                <span className="material-icons">menu</span></Link>
                        </h1>
                    </div>
                    <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"
                        id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion"
                        style={{transform: `translateX(${isMobileMenu ? '0%' : '-105%'})`}}>

                        <li className={`${activeLink === 'dashboard' ? 'active' : ''} bold`}><Link
                            className={`waves-effect waves-cyan ${activeLink === 'dashboard' ? 'active' : ''}`} to="#"
                            onClick={() => {
                                handleLink('');
                                setActiveLabel('');
                            }}><i className="material-icons">settings_input_svideo</i><span className="menu-title"
                                                                                            data-i11n="Dashboard">Dashboard</span></Link>
                        </li>
                        {(hasPermission(rolenames.role.model, rolenames.role.list, user) || hasPermission(rolenames.user.model, rolenames.user.list, user)) &&
                        <li className={`bold ${activeLabel === 'management' ? 'active open' : ''}`}><Link
                            className="collapsible-header waves-effect waves-cyan " to="#"
                            onClick={() => handleActiveLabel('management')}><i
                            className="material-icons">settings</i><span className="menu-title"
                                                                         data-i18n="Templates">Management</span></Link>
                            <div className={`collapsible-body ${activeLabel === 'management' ? 'activeSideMenu' : ''}`}>
                                <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                    {hasPermission(rolenames.role.model, rolenames.role.list, user) &&
                                    <li className={`${activeLink === 'roles' ? 'active' : ''} bold`}><Link
                                        className={`waves-effect waves-cyan ${activeLink === 'roles' ? 'active' : ''}`}
                                        to="#" onClick={() => handleLink('admin/roles')}><i
                                        className="material-icons">face</i><span data-i12n="User">Roles</span></Link>
                                    </li>
                                    }

                                    {hasPermission(rolenames.user.model, rolenames.user.list, user) &&
                                    <li className={`${activeLink === 'users' ? 'active' : ''} bold`}><Link
                                        className={`waves-effect waves-cyan ${activeLink === 'users' ? 'active' : ''}`}
                                        to="#" onClick={() => handleLink('admin/users')}><i
                                        className="material-icons">face</i><span data-i12n="User">User</span></Link>
                                    </li>
                                    }

                                </ul>
                            </div>
                        </li>
                        }
                        {(
                            hasPermission(rolenames.sources.model, rolenames.sources.list, user)
                            || hasPermission(rolenames.department.model, rolenames.department.list, user)
                            || hasPermission(rolenames.centers.model, rolenames.centers.list, user)
                            || hasPermission(rolenames.team.model, rolenames.team.list, user)
                            || hasPermission(rolenames.scholarship.model, rolenames.scholarship.list, user)
                            || hasPermission(rolenames.course.model, rolenames.course.list, user)
                            || hasPermission(rolenames.coursefee.model, rolenames.coursefee.list, user)
                            || hasPermission(rolenames.coursetopic.model, rolenames.coursetopic.list, user)
                            || hasPermission(rolenames.coursesubtopic.model, rolenames.coursesubtopic.list, user)
                            || hasPermission(rolenames.subject.model, rolenames.subject.list, user)
                            || hasPermission(rolenames.fee.model, rolenames.fee.list, user)) &&
                        <li className={`bold ${activeLabel === 'master_data' ||
                        activeLink === 'branches' ||
                        activeLink === 'centers' ||
                        activeLink === 'sources' ||
                        activeLink === 'departments' ||
                        activeLink === 'boards' ||
                        activeLink === 'tags' ||
                        activeLink === 'classes' ||
                        activeLink === 'sections' ||
                        activeLink === 'teams' ||
                        activeLink === 'scholarships' ||
                        activeLink === 'fees' ||
                        activeLink === 'courses' ||
                        activeLink === 'course-fees' ||
                        activeLink === 'course-topics' ||
                        activeLink === 'course-subtopics' ||
                        activeLink === 'subjects' ||
                        activeLink === 'announcement' ||
                        activeLink === 'leave-user-list' ||
                        activeLink === 'announcements' ||
                        activeLink === 'course-subjects'
                            ? 'active open' : ''}`}><Link className="collapsible-header waves-effect waves-cyan " to="#"
                                                          onClick={() => handleActiveLabel('master_data')}><i
                            className="material-icons">dns</i><span className="menu-title" data-i14n="Templates">Master Data</span></Link>
                            <div className={`collapsible-body ${activeLabel === 'master_data' ||
                            activeLink === 'branches' ||
                            activeLink === 'centers' ||
                            activeLink === 'sources' ||
                            activeLink === 'departments' ||
                            activeLink === 'boards' ||
                            activeLink === 'tags' ||
                            activeLink === 'classes' ||
                            activeLink === 'sections' ||
                            activeLink === 'teams' ||
                            activeLink === 'scholarships' ||
                            activeLink === 'fees' ||
                            activeLink === 'courses' ||
                            activeLink === 'course-fees' ||
                            activeLink === 'course-topics' ||
                            activeLink === 'course-subtopics' ||
                            activeLink === 'subjects' ||
                            activeLink === 'announcement' ||
                            activeLink === 'leave-master-list' ||
                            activeLink === 'leave-type-list' ||
                            activeLink === 'holiday-master-list' ||
                            activeLink === 'leave-user-list' ||
                            activeLink === 'announcements' ||
                            activeLink === 'course-subjects'
                                ? 'activeSideMenu' : ''}`}>
                                <ul className="collapsible collapsible-sub" data-collapsible="accordion">

                                    {hasPermission(rolenames.zone.model, rolenames.zone.list, user) &&
                                    <li className={`${activeLink === 'branches' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'branches' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/branches')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i15n="Zone">Zone</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.centers.model, rolenames.centers.list, user) &&
                                    <li className={`${activeLink === 'centers' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'centers' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/centers')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i16n="Center">Center</span></Link>
                                    </li>
                                    }

                                    {hasPermission(rolenames.sources.model, rolenames.sources.list, user) &&
                                    <li className={`${activeLink === 'sources' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'sources' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/sources')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i17n="Sources">Sources</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.department.model, rolenames.department.list, user) &&
                                    <li className={`${activeLink === 'departments' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'departments' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/departments')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i18n="Department">Department</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.boards.model, rolenames.boards.list, user) &&
                                    <li className={`${activeLink === 'boards' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'boards' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/boards')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i24n="Course Fee">Boards</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.class.model, rolenames.class.list, user) &&
                                    <li className={`${activeLink === 'classes' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'classes' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/classes')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i19n="Class">Class</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.section.model, rolenames.section.list, user) &&
                                    <li className={`${activeLink === 'sections' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'sections' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/sections')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i24n="Course Fee">Sections</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.team.model, rolenames.team.list, user) &&
                                    <li className={`${activeLink === 'teams' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'teams' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/teams')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i20n="Team">Team</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.scholarship.model, rolenames.scholarship.list, user) &&
                                    <li className={`${activeLink === 'scholarships' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'scholarships' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/scholarships')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i21n="Scholarship">Scholarship</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.fee.model, rolenames.fee.list, user) &&
                                    <li className={`${activeLink === 'fees' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'fees' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/fees')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i22n="Fee">Fee</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.tags.model, rolenames.tags.list, user) &&
                                    <>
                                        <li className={`${activeLink === 'tags' ? 'active' : ''} bold`}>
                                            <Link
                                                className={`waves-effect waves-cyan ${activeLink === 'tags' ? 'active' : ''}`}
                                                to="#" onClick={() => {
                                                handleLink('admin/tags')
                                            }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                              data-i16n="Center">Tags</span></Link>
                                        </li>
                                    </>
                                    }
                                    {hasPermission(rolenames.course.model, rolenames.course.list, user) &&
                                    <li className={`${activeLink === 'courses' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'courses' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/courses')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i23n="Course">Course</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.coursefee.model, rolenames.coursefee.list, user) &&
                                    <li className={`${activeLink === 'course-fees' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'course-fees' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/course-fees')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i24n="Course Fee">Course Fee</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.coursetopic.model, rolenames.coursetopic.list, user) &&
                                    <li className={`${activeLink === 'course-topics' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'course-topics' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/course-topics')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i24n="Course Fee">Course Topics</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.coursesubtopic.model, rolenames.coursesubtopic.list, user) &&
                                    <li className={`${activeLink === 'course-subtopics' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'course-subtopics' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/course-subtopics')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i24n="Course Fee">Course Sub Topics</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.subject.model, rolenames.subject.list, user) &&
                                    <li className={`${activeLink === 'subjects' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'subjects' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/subjects')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i24n="Course Fee">Subjects</span></Link>
                                    </li>
                                    }
                                    {hasPermission(rolenames.announcement.model, rolenames.announcement.list, user) &&
                                    <li className={`${activeLink === 'announcements' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'announcements' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/announcements')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i15n="Announcements">Announcements</span></Link>
                                    </li>
                                    }

                                    {hasPermission(rolenames.leavemaster.model, rolenames.leavemaster.list, user) &&
                                    <li className={`${activeLink === 'leave-master-list' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'leave-master-list' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/leave-master-list')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i15n="Leavemaster">Leave Master</span></Link>
                                    </li>
                                    }

                                    {hasPermission(rolenames.leavetype.model, rolenames.leavetype.list, user) &&
                                    <li className={`${activeLink === 'leave-type-list' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'leave-type-list' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/leave-type-list')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i15n="Leavetype">Leave Type</span></Link>
                                    </li>
                                    }

                                    {hasPermission(rolenames.holiday.model, rolenames.holiday.list, user) &&
                                    <li className={`${activeLink === 'holiday-master-list' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'holiday-master-list' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/holiday-master-list')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i15n="holidamaster">Holidays</span></Link>
                                    </li>
                                    }

                                    {hasPermission(rolenames.leavemaster.model, rolenames.leavemaster.list, user) &&
                                    <li className={`${activeLink === 'leave-user-list' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'leave-user-list' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/leave-user-list')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i15n="holidamaster">User Leave List</span></Link>
                                    </li>
                                    }

{/*                                    {hasPermission(rolenames.additionalProduct.model, rolenames.additionalProduct.list, user) &&
                                    <li className={`${activeLink === 'additional-product-list' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'additional-product-list' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/additional-product-list')
                                        }}><i className="material-icons">grid_on</i><span className="menu-title"
                                                                                          data-i15n="additionalProductMaster">Additional Product List</span></Link>
                                    </li>
                                    }*/}
                                </ul>
                            </div>
                        </li>
                        }

                        <li className={`bold ${activeLabel === 'core_hr' || activeLink === 'admin/attendance-list' || activeLink === 'admin/create-leave' ? 'active open' : ''}`}>
                            <Link className="collapsible-header waves-effect waves-cyan " to="#"
                                  onClick={() => handleActiveLabel('core_hr')}><i
                                className="material-icons">supervisor_account</i><span className="menu-title"
                                                                                       data-i18n="Templates">HR</span></Link>
                            <div
                                className={`collapsible-body ${activeLabel === 'core_hr' || activeLink === 'admin/attendance-list' || activeLink === 'admin/create-leave' ? 'activeSideMenu' : ''}`}>
                                <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                    <li className={`${activeLink === 'create-leave' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'create-leave' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/create-leave')
                                        }}><i className="material-icons">checked_bag</i><span className="menu-title"
                                                                                              data-i205n="Leads">Leave</span></Link>
                                    </li>
                                    <li className={`${activeLink === 'attendance-list' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'attendance-list' ? 'active' : ''}`}
                                            to="#" onClick={() => {
                                            handleLink('admin/attendance-list')
                                        }}><i className="material-icons">date_range</i><span className="menu-title"
                                                                                             data-i205n="Attendance">Attendance</span></Link>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        {hasPermission(rolenames.leads.model, rolenames.leads.list, user) &&
                        <li className={`${activeLink === 'leads' ? 'active' : ''} bold`}>
                            <Link className={`waves-effect waves-cyan ${activeLink === 'leads' ? 'active' : ''}`} to="#"
                                  onClick={() => {
                                      handleLink('admin/leads')
                                  }}><i className="material-icons">account_circle</i><span className="menu-title"
                                                                                           data-i25n="Leads">Leads</span></Link>
                        </li>}

                        {hasPermission(rolenames.leads.model, rolenames.leads.list, user) &&
                        <li className={`${activeLink === 'workshop' ? 'active' : ''} bold`}>
                            <Link className={`waves-effect waves-cyan ${activeLink === 'workshop' ? 'active' : ''}`}
                                  to="#" onClick={() => {
                                handleLink('admin/workshop')
                            }}><i className="material-icons">person</i><span className="menu-title" data-i205n="Leads">Workshop Leads</span></Link>
                        </li>}

                        {hasPermission(rolenames.counselling.model, rolenames.counselling.list, user) &&
                        <li className={`bold ${activeLabel === 'counselling' || activeLink === 'from-lead' || activeLink === 'counselling' ? 'active open' : ''}`}>
                            <Link className="collapsible-header waves-effect waves-cyan " to="#"
                                  onClick={() => handleActiveLabel('counselling')}><i
                                className="material-icons">groups</i><span className="menu-title"
                                                                           data-i18n="Templates">Counselling</span></Link>
                            <div
                                className={`collapsible-body ${activeLabel === 'counselling' || activeLink === 'from-lead' || activeLink === 'counselling' ? 'activeSideMenu' : ''}`}>
                                <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                    {hasPermission(rolenames.counselling.model, rolenames.counselling.create, user) &&
                                    <>
                                        <li className={`${activeLink === 'from-lead' ? 'active' : ''} bold`}><Link
                                            className={`waves-effect waves-cyan ${activeLink === 'from-lead' ? 'active' : ''}`}
                                            to="#" onClick={() => handleLink('admin/counselling/add/from-lead')}><i
                                            className="material-icons">grid_on</i><span data-i12n="admission-search">Lead to Counselling</span></Link>
                                        </li>

                                        <li className={`${activeLink === 'admin/counselling/add' ? 'active' : ''} bold`}>
                                            <Link
                                                className={`waves-effect waves-cyan ${activeLink === 'admin/counselling/add' ? 'active' : ''}`}
                                                to="#" onClick={() => handleLink('admin/counselling/add')}><i
                                                className="material-icons">grid_on</i><span
                                                data-i12n="admission-search">Counselling Add</span></Link>
                                        </li>
                                    </>
                                    }
                                    <li className={`${activeLink === 'counselling' ? 'active' : ''} bold`}><Link
                                        className={`waves-effect waves-cyan ${activeLink === 'counselling' ? 'active' : ''}`}
                                        to="#" onClick={() => handleLink('admin/counselling')}><i
                                        className="material-icons">grid_on</i><span data-i13n="List">List</span></Link>
                                    </li>
                                </ul>
                            </div>
                        </li>}

                        {hasPermission(rolenames.admission.model, rolenames.admission.list, user) &&
                        <li className={`bold ${activeLabel === 'admission' || activeLink === 'search' || activeLink === 'admission-list' ? 'active open' : ''}`}>
                            <Link className="collapsible-header waves-effect waves-cyan " to="#"
                                  onClick={() => handleActiveLabel('admission')}><i
                                className="material-icons">assignment_ind</i><span className="menu-title"
                                                                                   data-i18n="Templates">Admission</span></Link>
                            <div
                                className={`collapsible-body ${activeLabel === 'admission' || activeLink === 'search' || activeLink === 'admission-list' ? 'activeSideMenu' : ''}`}>
                                <ul className="collapsible collapsible-sub" data-collapsible="accordion">
                                    {/*<li className={`${activeLink ==='admission/new'?'active':''} bold`}><Link className={`waves-effect waves-cyan ${activeLink === 'admission/new'?'active':''}`} to="#" onClick={()=>handleLink('admin/admission/new')}><i className="material-icons">grid_on</i><span data-i12n="admission-search">Admission Form</span></Link>
              </li>*/}
                                    {hasPermission(rolenames.admission.model, rolenames.admission.create, user) &&
                                    <li className={`${activeLink === 'admission/search' ? 'active' : ''} bold`}><Link
                                        className={`waves-effect waves-cyan ${activeLink === 'admission/search' ? 'active' : ''}`}
                                        to="#" onClick={() => handleLink('admin/admission/search')}><i
                                        className="material-icons">grid_on</i><span data-i12n="admission-search">Admission Search</span></Link>
                                    </li>
                                    }
                                    <li className={`${activeLink === 'admission-list' ? 'active' : ''} bold`}><Link
                                        className={`waves-effect waves-cyan ${activeLink === 'admission-list' ? 'active' : ''}`}
                                        to="#" onClick={() => handleLink('admin/admission-list')}><i
                                        className="material-icons">grid_on</i><span data-i13n="List">List</span></Link>
                                    </li>

                                    <li className={`${activeLink === 'admission/installment/details' ? 'active' : ''} bold`}>
                                        <Link
                                            className={`waves-effect waves-cyan ${activeLink === 'admission/installment/details' ? 'active' : ''}`}
                                            to="#" onClick={() => handleLink('admin/admission/installment/details')}><i
                                            className="material-icons">account_balance_wallet</i><span
                                            data-i13n="details">Installment</span></Link>
                                    </li>
                                </ul>
                            </div>
                        </li>}
                        {/*hasPermission(rolenames.facultyAssign.model,rolenames.facultyAssign.list,user) &&
        <li className="bold"><Link className={`${activeLink ==='faculty-assign'?'active':''} bold`}
        to="#" onClick={()=>{handleLink('admin/faculty-assign') }}><i className="material-icons">done_all</i><span className="menu-title" data-i36n="Faculty Assign">Faculty Assignment</span></Link>
          </li>*/}
                        {/*hasPermission(rolenames.academic.model,rolenames.academic.list,user) &&
        <li className="bold"><Link className={`${activeLink ==='academic'?'active':''} bold`} to="#"  onClick={()=>{handleLink('admin/academic/list') }}><i className="material-icons">account_balance</i><span className="menu-title" data-i27n="Academic">Academic</span></Link>
        </li>*/}
                        {/*hasPermission(rolenames.store.model,rolenames.store.list,user) &&
        <li className="bold"><Link className={`${activeLink ==='store'?'active':''} bold`} to="#"  onClick={()=>{handleLink('admin/store') }}><i className="material-icons">add_shopping_cart</i><span className="menu-title" data-i28n="Store">Store</span></Link>
      </li>*/}
                        {/* <li className="bold"><Link className="waves-effect waves-cyan" to="app-chat.html"><i className="material-icons">chat_bubble_outline</i><span className="menu-title" data-i18n="Chat">Chat</span></Link>
        </li>
        <li className="bold"><Link className="waves-effect waves-cyan " to="app-todo.html"><i className="material-icons">check</i><span className="menu-title" data-i18n="ToDo">ToDo</span></Link>
        </li> */}
                    </ul>
                    <div className="navigation-background"/>
                    <Link
                        className="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only"
                        to="#" ref={mobileMenuRef} onClick={() => setIsMobileMenu(!isMobileMenu)}
                        data-target="slide-out"><i className="material-icons">menu</i></Link>
                </aside>
                : null}
        </>
    )

}

export default SideBar;