import HttpRequest from "./HttpRequest";
//=================Admission section==============
export const getAdmissionById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`admission/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addAdmission = async (data) => {
    try {
        const result = await (new HttpRequest()).postData('admission/store', data);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Admission added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Required field data is missing.'
        }
    }
}

export const updateAdmissionStudentDetails = async (data,id) => {
    try {
        const result = await (new HttpRequest()).postData(`admission/student-details/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Admission updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Required field data is missing.'
        }
    }
}

export const updateAdmission = async (data,id) => {
    try {
        const result = await (new HttpRequest()).postData(`admission/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Admission updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Required field data is missing.'
        }
    }
}

export const getAdmissions = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`admission/list`,params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra:{}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getCourseInstallments = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`admission/course/installments`,params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getCourseInstallmentDetails = async (params,id,flag) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`admission/course/installment/${id}`,params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const updateChequeReturn = async (params) => {
    try {
        const result = await (new HttpRequest()).postData(`admission/course/installment/cheque-return/update`,params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}


export const getCourseInstallmentPaymentDetails = async (params,id,flag) => {
    try {
        const result = await (new HttpRequest()).getData(`admission/course/installment/payment-details/${id}?from_admission=${flag}`,params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}




export const storeCourseInstallmentPayment = async (data,id) => {
    try {
        const result = await (new HttpRequest()).postData(`admission/course/installment/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Installment payment successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Required field data is missing.'
        }
    }
}

export const printInstallmentReceipt = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`admission/installment/print`,params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const installmentIncrease = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`admission/installment/increase`,params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const admissionsReportExport = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`admission/report/export`,params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra:{}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const installmentReportExport = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`admission/installment/report/export`,params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra:{}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const paymentReportExport = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`admission/payment/report/export`,params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra:{}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}