export const base = "";
export const baseDashboard = "/";
export const RouteNames = {
  regenPass: base + "/regenerate-password",
  login: base + "/login",

  //============== User Sections ====================
  branchList: base + "/admin/branches",
  dashboard: base + "/admin/dashboard",
  centerList: base + "/admin/centers",
  sourceList: base + "/admin/sources",
  roleList: base + "/admin/roles",
  roleCreate: base + "/admin/roles/create",
  roleEdit: base + "/admin/roles/edit",
  departmentList: base + "/admin/departments",
  classList: base + "/admin/classes",
  teamList: base + "/admin/teams",
  scholarshipList: base + "/admin/scholarships",
  feeList: base + "/admin/fees",
  courseList: base + "/admin/courses",
  courseFeeList: base + "/admin/course-fees",
  courseTopicList: base + "/admin/course-topics",
  courseSubTopicList: base + "/admin/course-subtopics",
  adminUserList: base + "/admin/users",
  adminUserAdd: base + "/admin/user/add",
  adminUserEdit: base + "/admin/user/edit",
  adminLeadList: base + "/admin/leads",
  adminLeadAdd: base + "/admin/lead/add",
  adminLeadEdit: base + "/admin/lead/edit",
  adminLeadView: base + "/admin/lead/view",
  adminWorkshopLeadList: base + "/admin/workshop",
  adminCounsellingAddFromLead: base + "/admin/counselling/add/from-lead",
  adminCounsellingAdd: base + "/admin/counselling/add",
  adminCounsellingEdit: base + "/admin/counselling/edit",
  adminCounsellingList: base + "/admin/counselling",
  adminAdmission: base + "/admin/admission",
  adminAdmissionAdd: base + "/admin/admission/add",
  adminAdmissionList: base + "/admin/admission-list",
  adminAdmissionDetails: base + "/admin/admission/details",
  adminAdmissionDetailsUpdate: base + "/admin/admission/details/update",
  adminAdmissionInstallments: base + "/admin/admission/installments",
  adminAdmissionInstallmentDetails: base + "/admin/admission/installment",
  adminAdmissionInstallmentChequeReturn: base + "/admin/admission/installment/cheque-return",
  courseInstallmentPayment: base + "/admin/course/installment/payment",

  adminAdmissionStudentDetailsEdit: base + "/admin/admission/student-details/edit",
  academic: base + "/admin/academic",
  academicStudentFeedBack: base + "/admin/academic/student/feedback",
  adminAcademicList: base + "/admin/academic/list",
  adminAcademicDetails: base + "/admin/academic/details",
  adminFacultyAssignList: base + "/admin/faculty-assign",
  adminFacultyAssignAdd: base + "/admin/faculty-assign/add",
  adminFacultyAssignEdit: base + "/admin/faculty-assign/edit",
  subjectList: base + "/admin/subjects",
  sectionList: base + "/admin/sections",
  boardList: base + "/admin/boards",
  tagList: base + "/admin/tags",
  courseSubjectList: base + "/admin/course-subjects",
  createLeave: base + "/admin/create-leave",
  leaveTypeList: base + "/admin/leave-type-list",
  leaveTypeEdit: base + "/admin/leave-type-edit",
  leaveTypeAdd: base + "/admin/leave-type-add",
  leaveMasterEdit: base + "/admin/leave-master-edit",
  leaveMasterList: base + "/admin/leave-master-list",
  leaveMasterAdd: base + "/admin/leave-master-add",
  leaveListUser:base + "/admin/leave-user-list",
  attendanceList:base + "/admin/attendance-list",
  holidayMasterEdit: base + "/admin/holiday-master-edit",
  holidayMasterList: base + "/admin/holiday-master-list",
  holidayMasterAdd: base + "/admin/holiday-master-add",
  announcementList: base + "/admin/announcements",

  additionalProductList: base + "/admin/additional-product-list",
  additionalProductAdd: base + "/admin/additional-product-add",
  additionalProductEdit: base + "/admin/additional-product-edit",
  //============ End User Sections =============
};
