import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {RouteNames, base, baseDashboard} from '../constants/RouteNames';
import ProtectedRoute from '../navigations/auth-navigation';
import FallBackLoader from '../Pages/FallBackLoader';
//import AdmissionDetailsUpdate from '../Pages/admission/AdmissionDetailsUpdate';
import ChequeReturn from '../Pages/admission/ChequeReturn';
import AdmissionDetailsUpdateByCourse from "../Pages/admission/AdmissionDetailsUpdateByCourse";


const Login = lazy(() => import("../Pages/auth/Login"));
const RegeneratePassword = lazy(() =>
    import("../Pages/auth/RegeneratePassword")
);
const Dashboard = lazy(() => import("../Pages/dashboard/Dashboard"));
const UserList = lazy(() => import("../Pages/users-admin/UsersList"));
const RoleList = lazy(() => import("../Pages/roles/Role"));
const RoleCreate = lazy(() => import("../Pages/roles/RoleCreate"));
const LeadList = lazy(() => import("../Pages/leads/LeadList"));
const LeadWorkShopList = lazy(() => import("../Pages/leads/LeadWorkShopList"));
const CounsellingList = lazy(() => import("../Pages/counselling/CounsellingList"));
const UserEdit = lazy(() => import("../Pages/users-admin/UserEdit"));
const LeadEdit = lazy(() => import("../Pages/leads/LeadEdit"));
const LeadDetails = lazy(() => import("../Pages/leads/LeadDetails"));
const CounsellingEdit = lazy(() => import("../Pages/counselling/CounsellingEdit"));
const CounsellingAddFromLead = lazy(() => import("../Pages/counselling/CounsellingAddFromLead"));
const BranchList = lazy(() => import("../Pages/masters/branchs/BranchList"));
const CenterList = lazy(() => import("../Pages/masters/centers/CenterList"));
const SourceList = lazy(() => import("../Pages/masters/sources/SourceList"));
const DepartmentList = lazy(() => import("../Pages/masters/departments/DepartmentList"));
const ClassList = lazy(() => import("../Pages/masters/classes/ClassList"));
const TeamList = lazy(() => import("../Pages/masters/teams/TeamList"));
const ScholarshipList = lazy(() => import("../Pages/masters/scholarships/ScholarshipList"));
const FeeList = lazy(() => import("../Pages/masters/fees/FeeList"));
const CourseList = lazy(() => import("../Pages/masters/courses/CourseList"));
const CourseTopicList = lazy(() => import("../Pages/masters/course-topics/CourseTopicList"));
const CourseSubTopicList = lazy(() => import("../Pages/masters/course-subtopics/CourseSubTopicList"));
const Admission = lazy(() => import("../Pages/admission/Admission"));
const AdmissionList = lazy(() => import("../Pages/admission/AdmissionList"));
const AdmissionDetails = lazy(() => import("../Pages/admission/AdmissionDetails"));
const AdmissionInstallments = lazy(() => import("../Pages/admission/AdmissionInstallments"));
const AdmissionInstallmentDetails = lazy(() => import("../Pages/admission/InstallmentDetails"));
const InstallmentPayment = lazy(() => import("../Pages/admission/InstallmentPayment"));
const StudentDetailsEdit = lazy(() => import("../Pages/admission/StudentDetailsEdit"));
const CourseFeeList = lazy(() => import("../Pages/masters/course-fees/CourseFeeList"));
const SubjectList = lazy(() => import("../Pages/masters/subjects/SubjectList"));
const SectionList = lazy(() => import("../Pages/masters/sections/SectionList"));
const BoardList = lazy(() => import("../Pages/masters/boards/BoardList"));
const TagList = lazy(() => import("../Pages/masters/tags/TagList"));
const CourseSubjectList = lazy(() => import("../Pages/masters/course-subjects/CourseSubjectList"));
const Academic = lazy(() => import("../Pages/academic/Academic"));
const AcademicList = lazy(() => import("../Pages/academic/AcademicList"));
const AcademicDetails = lazy(() => import("../Pages/academic/AcademicDetails"));
const FacultyAssignList = lazy(() => import("../Pages/faculty-assign/FacultyAssignList"));
const FacultyAssign = lazy(() => import("../Pages/faculty-assign/FacultyAssign"));
const StudentFeedback = lazy(() => import("../Pages/academic/StudentFeedback"));
const AnnouncementList = lazy(() => import("../Pages/masters/announcements/AnnouncementList"));

const NotFound = lazy(() => import("../Pages/404/NotFound"));
const CreateLeave = lazy(() => import("../Pages/leave/CreateLeave"))
const LeaveTypeList = lazy(() => import("../Pages/masters/leavetype/LeaveTypeList"))
const LeaveTypeEdit = lazy(() => import("../Pages/masters/leavetype/LeaveTypeEdit"))
const LeaveMasterEdit = lazy(() => import("../Pages/masters/leavemaster/LeaveMasterEdit"))
const LeaveMasterList = lazy(() => import("../Pages/masters/leavemaster/LeaveMasterList"))
const HolidayMasterEdit = lazy(() => import("../Pages/masters/holidaymaster/HolidayMasterEdit"))
const HolidayMasterList = lazy(() => import("../Pages/masters/holidaymaster/HolidayMasterList"))
const LeaveListUser = lazy(() => import("../Pages/masters/leavelist/LeaveList"))
const AttendanceList = lazy(() => import("../Pages/attendance/AttendanceList"))
//const AdditionalProductList = lazy(() => import("../Pages/masters/additional-products/AdditionalProductList"));
// import CreateLeave from '../Pages/leave/CreateLeave';

const DefaultNavigation = () => {
    return (
        <Suspense fallback={<FallBackLoader/>}>
            <Router>
                <Routes>
                    <Route exact path={RouteNames.login} element={<Login/>}/>
                    <Route exact path={RouteNames.regenPass} element={<RegeneratePassword/>}/>
                    {/** ========= State section =========== */}
                    {/** ========= Admin User section =========== */}
                    <Route exact path={RouteNames.branchList}
                           element={<ProtectedRoute exact path={RouteNames.branchList} component={BranchList}/>}/>
                    <Route exact path={RouteNames.centerList}
                           element={<ProtectedRoute exact path={RouteNames.centerList} component={CenterList}/>}/>
                    <Route exact path={RouteNames.sourceList}
                           element={<ProtectedRoute exact path={RouteNames.sourceList} component={SourceList}/>}/>
                    <Route exact path={RouteNames.departmentList}
                           element={<ProtectedRoute exact path={RouteNames.departmentList}
                                                    component={DepartmentList}/>}/>
                    <Route exact path={RouteNames.classList}
                           element={<ProtectedRoute exact path={RouteNames.classList} component={ClassList}/>}/>
                    <Route exact path={RouteNames.teamList}
                           element={<ProtectedRoute exact path={RouteNames.teamList} component={TeamList}/>}/>
                    <Route exact path={RouteNames.scholarshipList}
                           element={<ProtectedRoute exact path={RouteNames.scholarshipList}
                                                    component={ScholarshipList}/>}/>
                    <Route exact path={RouteNames.feeList}
                           element={<ProtectedRoute exact path={RouteNames.feeList} component={FeeList}/>}/>
                    <Route exact path={RouteNames.courseList}
                           element={<ProtectedRoute exact path={RouteNames.courseList} component={CourseList}/>}/>
                    <Route exact path={RouteNames.courseTopicList}
                           element={<ProtectedRoute exact path={RouteNames.courseTopicList}
                                                    component={CourseTopicList}/>}/>
                    <Route exact path={RouteNames.courseFeeList}
                           element={<ProtectedRoute exact path={RouteNames.courseFeeList} component={CourseFeeList}/>}/>
                    <Route exact path={RouteNames.courseSubTopicList}
                           element={<ProtectedRoute exact path={RouteNames.courseSubTopicList}
                                                    component={CourseSubTopicList}/>}/>
                    <Route exact path={RouteNames.subjectList}
                           element={<ProtectedRoute exact path={RouteNames.subjectList} component={SubjectList}/>}/>
                    <Route exact path={RouteNames.sectionList}
                           element={<ProtectedRoute exact path={RouteNames.sectionList} component={SectionList}/>}/>
                    <Route exact path={RouteNames.boardList}
                           element={<ProtectedRoute exact path={RouteNames.boardList} component={BoardList}/>}/>
                    <Route exact path={RouteNames.tagList}
                           element={<ProtectedRoute exact path={RouteNames.tagList} component={TagList}/>}/>
                    <Route exact path={RouteNames.courseSubjectList}
                           element={<ProtectedRoute exact path={RouteNames.courseSubjectList}
                                                    component={CourseSubjectList}/>}/>
                    <Route exact path={RouteNames.adminUserList}
                           element={<ProtectedRoute exact path={RouteNames.adminUserList} component={UserList}/>}/>
                    <Route exact path={RouteNames.adminUserAdd}
                           element={<ProtectedRoute exact path={RouteNames.adminUserAdd} component={UserEdit}/>}/>
                    <Route exact path={RouteNames.roleList}
                           element={<ProtectedRoute exact path={RouteNames.roleList} component={RoleList}/>}/>
                    <Route exact path={RouteNames.roleCreate}
                           element={<ProtectedRoute exact path={RouteNames.roleCreate} component={RoleCreate}/>}/>
                    <Route exact path={RouteNames.roleEdit + "/:id"}
                           element={<ProtectedRoute exact path={RouteNames.roleEdit + "/:id"}
                                                    component={RoleCreate}/>}/>
                    <Route exact path={RouteNames.createLeave}
                           element={<ProtectedRoute exact path={RouteNames.createLeave} component={CreateLeave}/>}/>
                    <Route exact path={RouteNames.leaveTypeList}
                           element={<ProtectedRoute exact path={RouteNames.leaveTypeList} component={LeaveTypeList}/>}/>
                    <Route exact path={RouteNames.leaveTypeList}
                           element={<ProtectedRoute exact path={RouteNames.leaveTypeList} component={LeaveTypeList}/>}/>
                    <Route exact path={RouteNames.leaveTypeEdit + "/:id"}
                           element={<ProtectedRoute exact path={RouteNames.leaveTypeEdit + "/:id"}
                                                    component={LeaveTypeEdit}/>}/>
                    <Route exact path={RouteNames.leaveTypeAdd}
                           element={<ProtectedRoute exact path={RouteNames.leaveTypeAdd} component={LeaveTypeEdit}/>}/>

                    <Route exact path={RouteNames.leaveMasterList}
                           element={<ProtectedRoute exact path={RouteNames.leaveMasterList}
                                                    component={LeaveMasterList}/>}/>
                    <Route exact path={RouteNames.leaveMasterEdit + "/:id"}
                           element={<ProtectedRoute exact path={RouteNames.leaveMasterEdit + "/:id"}
                                                    component={LeaveMasterEdit}/>}/>
                    <Route exact path={RouteNames.leaveMasterAdd}
                           element={<ProtectedRoute exact path={RouteNames.leaveMasterAdd}
                                                    component={LeaveMasterEdit}/>}/>

                    <Route exact path={RouteNames.holidayMasterList}
                           element={<ProtectedRoute exact path={RouteNames.holidayMasterList}
                                                    component={HolidayMasterList}/>}/>
                    <Route exact path={RouteNames.holidayMasterEdit + "/:id"}
                           element={<ProtectedRoute exact path={RouteNames.holidayMasterEdit + "/:id"}
                                                    component={HolidayMasterEdit}/>}/>
                    <Route exact path={RouteNames.holidayMasterAdd}
                           element={<ProtectedRoute exact path={RouteNames.holidayMasterAdd}
                                                    component={HolidayMasterEdit}/>}/>
                    <Route exact path={RouteNames.leaveListUser}
                           element={<ProtectedRoute exact path={RouteNames.leaveListUser} component={LeaveListUser}/>}/>
                    <Route exact path={RouteNames.leaveListUser + "/:id"}
                           element={<ProtectedRoute exact path={RouteNames.leaveListUser + "/:id"}
                                                    component={LeaveListUser}/>}/>
                    <Route exact path={RouteNames.attendanceList}
                           element={<ProtectedRoute component={AttendanceList}/>}/>
                    <Route exact path={RouteNames.attendanceList + "/:id"}
                           element={<ProtectedRoute component={AttendanceList}/>}/>

                    {/*<Route exact path={RouteNames.additionalProductList} element={<ProtectedRoute component={AdditionalProductList} />} />*/}

                    {/* <ProtectedRoute exact path={RouteNames.adminUserList} component={UserList} /> */}

                    <Route exact path={RouteNames.adminLeadAdd}
                           element={<ProtectedRoute exact path={RouteNames.adminLeadAdd} component={LeadEdit}/>}/>
                    <Route exact path={`${RouteNames.adminLeadEdit}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminLeadEdit}/:id`}
                                                    component={LeadEdit}/>}/>
                    <Route exact path={`${RouteNames.adminLeadView}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminLeadView}/:id`}
                                                    component={LeadDetails}/>}/>
                    <Route exact path={RouteNames.adminCounsellingList}
                           element={<ProtectedRoute exact path={RouteNames.adminCounsellingList}
                                                    component={CounsellingList}/>}/>
                    <Route exact path={`${RouteNames.adminAdmission}/:flag`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminAdmission}/:flag`}
                                                    component={Admission}/>}/>
                    <Route exact path={`${RouteNames.adminAdmissionAdd}/:flag`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminAdmissionAdd}/:flag`}
                                                    component={Admission}/>}/>
                    <Route exact path={`${RouteNames.adminAdmissionDetails}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminAdmissionDetails}/:id`}
                                                    component={AdmissionDetails}/>}/>
                    {/*<Route exact path={`${RouteNames.adminAdmissionDetailsUpdate}/:id`}*/}
                    {/*       element={<ProtectedRoute exact path={`${RouteNames.adminAdmissionDetailsUpdate}/:id`}*/}
{/*                                                    component={AdmissionDetailsUpdate}/>}/>*/}
                    <Route exact path={`${RouteNames.adminAdmissionDetailsUpdate}/:admission_id/:course_id`}
                           element={<ProtectedRoute exact
                                                    path={`${RouteNames.adminAdmissionDetailsUpdate}/:admission_id/:course_id`}
                                                    component={AdmissionDetailsUpdateByCourse}/>}/>
                    <Route exact path={`${RouteNames.adminAdmissionInstallments}/:id/:flag`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminAdmissionInstallments}/:id/:flag`}
                                                    component={AdmissionInstallments}/>}/>
                    <Route exact path={`${RouteNames.adminAdmissionInstallmentDetails}/:flag`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminAdmissionInstallmentDetails}/:flag`}
                                                    component={AdmissionInstallmentDetails}/>}/>
                    <Route exact path={`${RouteNames.adminAdmissionInstallmentChequeReturn}/:id/:flag`}
                           element={<ProtectedRoute exact
                                                    path={`${RouteNames.adminAdmissionInstallmentChequeReturn}/:id/:flag`}
                                                    component={ChequeReturn}/>}/>
                    <Route exact path={`${RouteNames.courseInstallmentPayment}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.courseInstallmentPayment}/:id`}
                                                    component={InstallmentPayment}/>}/>
                    <Route exact path={`${RouteNames.adminAdmissionStudentDetailsEdit}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminAdmissionStudentDetailsEdit}/:id`}
                                                    component={StudentDetailsEdit}/>}/>

                    <Route exact path={`${RouteNames.academicStudentFeedBack}/:id/:uid`} component={StudentFeedback}/>

                    <Route exact path={RouteNames.adminAdmissionList}
                           element={<ProtectedRoute exact path={RouteNames.adminAdmissionList}
                                                    component={AdmissionList}/>}/>
                    <Route exact path={RouteNames.adminCounsellingAddFromLead}
                           element={<ProtectedRoute exact path={RouteNames.adminCounsellingAddFromLead}
                                                    component={CounsellingAddFromLead}/>}/>
                    <Route exact path={RouteNames.adminCounsellingAdd}
                           element={<ProtectedRoute exact path={RouteNames.adminCounsellingAdd}
                                                    component={CounsellingEdit}/>}/>
                    <Route exact path={`${RouteNames.adminCounsellingEdit}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminCounsellingEdit}/:id`}
                                                    component={CounsellingEdit}/>}/>
                    <Route exact path={RouteNames.adminLeadList}
                           element={<ProtectedRoute exact path={RouteNames.adminLeadList} component={LeadList}/>}/>
                    <Route exact path={RouteNames.adminWorkshopLeadList}
                           element={<ProtectedRoute exact path={RouteNames.adminWorkshopLeadList}
                                                    component={LeadWorkShopList}/>}/>
                    <Route exact path={`${RouteNames.adminUserEdit}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminUserEdit}/:id`}
                                                    component={UserEdit}/>}/>
                    <Route exact path={RouteNames.adminFacultyAssignList}
                           element={<ProtectedRoute exact path={RouteNames.adminFacultyAssignList}
                                                    component={FacultyAssignList}/>}/>
                    <Route exact path={RouteNames.adminFacultyAssignAdd}
                           element={<ProtectedRoute exact path={RouteNames.adminFacultyAssignAdd}
                                                    component={FacultyAssign}/>}/>
                    <Route exact path={`${RouteNames.adminFacultyAssignEdit}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminFacultyAssignEdit}/:id`}
                                                    component={FacultyAssign}/>}/>
                    <Route exact path={RouteNames.academic}
                           element={<ProtectedRoute exact path={RouteNames.academic} component={Academic}/>}/>
                    <Route exact path={RouteNames.adminAcademicList}
                           element={<ProtectedRoute exact path={RouteNames.adminAcademicList}
                                                    component={AcademicList}/>}/>
                    <Route exact path={`${RouteNames.adminAcademicDetails}/:id`}
                           element={<ProtectedRoute exact path={`${RouteNames.adminAcademicDetails}/:id`}
                                                    component={AcademicDetails}/>}/>
                    <Route exact path={RouteNames.announcementList}
                           element={<ProtectedRoute exact path={RouteNames.announcementList}
                                                    component={AnnouncementList}/>}/>
                    <Route exact path={RouteNames.dashboard}
                           element={<ProtectedRoute exact path={RouteNames.dashboard} component={Dashboard}/>}/>
                    <Route exact path={baseDashboard} element={<ProtectedRoute component={Dashboard}/>}/>

                    {/**============= 404 Page Handler =================== */}
                    <Route path="*" component={NotFound}/>
                </Routes>
            </Router>
        </Suspense>
    );
}

export default DefaultNavigation;
