import React from 'react';

function FallBackLoader() {
    return (
        <div className="fallback_main">
            <div className="loader">Loading...</div>
        </div>
    );
}

export default FallBackLoader;
