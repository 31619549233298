import React,{useRef, useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import NotificationsAlert from "./components/NotificationsAlert";
import { adjustSideBar, logoutUser } from "../store/actions/auth";
import { base } from "../constants/RouteNames";

const TopBar = ({ title }) => {
    const sideBarStatus = useSelector(state => state.auth.sideBarStatus);
    const dispatch = useDispatch();
    const notificationRef = useRef()
    const profileDropdownRef= useRef()
    const user = useSelector( state => state.auth.user )
    const languageRef = useRef()
    const [isNotificationOpen, setIsNotificationOpen] = useState(false)
    const [isOpenProfile, setIsOpenProfile] = useState(false)
    const [isOpenLanguage, setIsOpenLanguage] = useState(false)

    const logoutHandler = () => {
      dispatch(logoutUser());
      toast.success('You are successfully logged out.')
    }

    const _updateSideBarStatus = (status) => {
        dispatch(adjustSideBar(status));
    }

    useEffect(() => {
      document.addEventListener("click", handleClickOutSide, true);
      return () => {
        document.removeEventListener("click", handleClickOutSide, true);
      }
    }, [])

    const handleClickOutSide =(event)=>{
      if(notificationRef.current && !notificationRef.current.contains(event.target) ){
        setIsNotificationOpen(false)
      }
      if(profileDropdownRef.current && !profileDropdownRef.current.contains(event.target) ){
        setIsOpenProfile(false)
      }
      if(languageRef.current && !languageRef.current.contains(event.target) ){
        setIsOpenLanguage(false)
      }
    }

    return (
      <>
      {user && user.id>0 ?
        <header className="page-topbar" id="header">
          <div className="navbar navbar-fixed">
            <nav className="navbar-main navbar-color nav-collapsible sideNav-lock navbar-dark gradient-45deg-indigo-purple no-shadow">
              <div className="nav-wrapper">
                <ul className="navbar-list right">
                  <li className="hide-on-large-only search-input-wrapper"><Link className="waves-effect waves-block waves-light search-button" to="#"><i className="material-icons">search</i></Link></li>
                  {/*<li><Link className="waves-block waves-light notification-button" to="#" ref={notificationRef} onClick={()=> setIsNotificationOpen(!isNotificationOpen)} data-target="notifications-dropdown"><i className="material-icons">notifications_none<small className="notification-badge">5</small></i></Link>
                      <ul className={`dropdown-content ${isNotificationOpen?'notifications-dropdown':''}`} id="notifications-dropdown">
                        <li>
                          <h6>NOTIFICATIONS<span className="new badge">5</span></h6>
                        </li>
                        <li className="divider"></li>
                        <li><Link className="black-text" to="#"><span className="material-icons icon-bg-circle cyan small">add_shopping_cart</span> A new order has been placed!</Link>
                          <time className="media-meta grey-text darken-2" dateTime="2015-06-12T20:50:48+08:00">2 hours ago</time>
                        </li>
                      </ul>
                  </li>*/}
                  <li><Link className="waves-block waves-light profile-button" to="#" ref={profileDropdownRef} onClick={()=> setIsOpenProfile(!isOpenProfile)} data-target="profile-dropdown"><span className="avatar-status avatar-online">
                      <img src={require('../assets/images/avatar/avatar.png')} alt="avatar" /><i/></span></Link>
                      <ul className={`dropdown-content ${isOpenProfile?'profile-dropdown':''}`} id="profile-dropdown">
                        {/*<li><Link className="grey-text text-darken-1" to="user-profile-page.html"><i className="material-icons">person_outline</i> Profile</Link></li>*/}
                        <li><Link className="grey-text text-darken-1" to="#" onClick={logoutHandler}><i className="material-icons">keyboard_tab</i> Logout</Link></li>
                      </ul>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>
      :null}
      </>
    );
};
export default TopBar;