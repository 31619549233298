/**
 * Dev Server
 */
//export const BASE_API_URL = '';
//export const BASE_SOCKET_ENDPOINT = '';

/**
 * Local Server
 */

// export const BASE_API_URL = production ? 'https://pxl-loyalty.in/perp-backend/api/' : 'http://localhost/perp-backend/api/';
//only for production
//const production = process.env.NODE_ENV == "production" ? true : true
//only for local

//Helper.appLog(process.env, 'ENV DETAILS');

//const production = process.env.NODE_ENV === "production";
//export const BASE_API_URL = production ? 'https://pathfindersms.com/perp-backend/api/' : 'https://pxl-loyalty.in/perp-backend/api/';
//export const BASE_SOCKET_ENDPOINT = production ? 'https://pathfindersms.com:8080' : 'http://localhost:8080';
//console.log(process.env, 'base url issues');

export const BASE_API_URL = process.env.REACT_APP_BASE_API_ENDPOINT;
export const BASE_SOCKET_ENDPOINT = process.env.REACT_APP_BASE_SOCKET_ENDPOINT;
//export const DOWNLOAD_FILE_URL = "";