import React from 'react';
import { useSelector } from 'react-redux';

function Loader() {
    const showLoader = useSelector(state => state.auth.showLoader);
    if( !showLoader )
        return <div></div>
    
    return (
      <div className="main-loader">
        <div className="preloader-wrapperdd big active">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
      </div>
    )
}

export default Loader;
