import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteNames, base } from '../../constants/RouteNames';
import useForm from '../../reuseable/hooks/useForm';
import { toggleLoader } from '../../store/actions/auth';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCourseInstallmentPaymentDetails, updateChequeReturn } from '../../store/actions/admissions';
import moment from 'moment';
import {
    TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import DefaultStructure from '../../layouts/defaultStructure';
import { Link } from 'react-router-dom';
import Activity from '../counselling/components/activity';
import CheckBoxComp from './Components/CheckBoxComp';
import { useNavigate } from 'react-router-dom';
import {Helper} from "../../helpers/helper";
import {Navigate} from "react-router";

function ChequeReturn() {
    const { id, flag } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);
    const [redirectFlag, setRedirectFlag] = useState(false);
    const [installmentDetails, setInstallmentDetails] = useState({});
    const [isSubmitBtnPressed, setIsSubmitBtnPressed] = useState(false);
    const [redirectToList, setRedirectToList] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [chequeReturnText, setChequeReturnText] = useState();
    const [paymentDetails, setPaymentDetails] = useState([])
    const [checkedInstallment, setCheckedInstallment] = useState([]);
    const userReauthenticate = ({ message }) => {
        toast.error(message, {
            onOpen: () => setRedirectFlag(true)
        });
    }
    // Helper.appLog('=========TTTTTTTTTTTTTTTTTT=========');
    // Helper.appLog({a:typeof flag,f:flag,b:parseInt(flag) === 1});
    // Helper.appLog('====================================');

    const formFields = {

    };
    const [state, inputHandler, _, updateForm] = useForm(
        formFields,
        false
    );

    const [paydetails, setPaydetails] = React.useState([
        { payment_type: '', scp_number: '', scp_date: '', scp_bank: '', scp_amount: '' }
    ]);

    const handleAddPaymentDetails = () => {
        setPaydetails([...paydetails, { payment_type: '', scp_number: '', scp_date: '', scp_bank: '', scp_amount: '' }]);
    }

    const handleRemovePaymentDetails = (rmvid) => {
        setPaydetails(paydetails.filter((payid, index) => index != rmvid));
    }

    const handlePaymentInputChange = (index, event) => {
        if (event.target) {
            const values = [...paydetails];
            if (event.target.name == 'scp_amount') {
                values[index][event.target.name] = parseInt(event.target.value);
            } else {
                values[index][event.target.name] = event.target.value;
            }
            setPaydetails(values);
        } else {
            const values = [...paydetails];
            values[index]['scp_date'] = event ? moment(event).format('YYYY-MM-DD') : '';
            setPaydetails(values);
        }

    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (id) {
            dispatch(toggleLoader(true));
            // if (parseInt(flag) === 0) {
            //     getCourseInstallmentDetails({}, id, flag).then(
            //         response => {
            //             dispatch(toggleLoader(false));
            //             Helper.appLog(response.data, 56666);
            //             if (!response.error) {
            //                 setInstallmentDetails(response.data.installment);
            //             }
            //             if (response.redirect)
            //                 userReauthenticate(response);
            //         }
            //     );
            // }

            getCourseInstallmentPaymentDetails({}, id, flag).then(
                response => {
                    dispatch(toggleLoader(false));
                    Helper.appLog(response.data, 56666);
                    if (!response.error) {
                         setInstallmentDetails(response.data.data.installment_details);
                        // Helper.appLog('====================================');
                        // Helper.appLog(response);
                        // Helper.appLog('====================================');
                        setPaymentDetails(response.data.data.paymentDetails);

                    }
                    if (response.redirect)
                        userReauthenticate(response);
                }
            );
        }
    }, [id]);




    const paymentTypeCheck = (data) => {
        if (data === 1) {
            return ('Payorder')
        } else if (data === 2) {
            return ('Bank draft')
        } else if (data === 3) {
            return ('Cheque')
        } else if (data === 4) {
            return ('Post dated cheque')
        } else if (data === 5) {
            return ('Cash')
        } else if (data === 6) {
            return ('UPI')
        } else if (data === 7) {
            return ('Credit Card')
        } else {
            return ('Debit Card')
        }
    }

    const handelInstallmentCheck = (isChecked, id) => {

        if (isChecked) {
            const addIn = paymentDetails.filter((data) => data.id === id)
            setCheckedInstallment([...checkedInstallment, addIn[0]])
        } else {
            setCheckedInstallment(checkedInstallment.filter(item => item.id !== id))
        }
    }



    const handelForm = async () => {
        if (checkedInstallment.length > 0) {
            if (chequeReturnText) {
                if (isNaN(parseInt(chequeReturnText))) {
                    toast.error('Please Enter Number')
                } else {
                    for (let i = 0; i < paymentDetails.length; i++) {
                        if (checkedInstallment.filter(item => item.id === paymentDetails[i].id).length > 0) {
                            paymentDetails[i] = { ...paymentDetails[i], is_canceled: 1 }
                        } else {
                            paymentDetails[i] = { ...paymentDetails[i], is_canceled: 0 }
                        }
                    }
                    const response = await updateChequeReturn({ paymentDetails, return_charge: chequeReturnText});
                    if (!response.error) {
                        toast.success('Updated Successfully');
                        navigate(`${base}/admin/admission-list`);

                    } else {
                        toast.error('Something went wrong')
                    }
                }


            } else {
                toast.error('Please fill cheque return charges')
            }
        } else {
            toast.error('Nothing to update , please Select one Payment Type')
        }
    }
    // Helper.appLog('====================================');
    // Helper.appLog(installmentDetails);
    // Helper.appLog('====================================');

    if (redirectFlag)
        return <Navigate to={RouteNames.login}/>
    if (redirectToList)
        return <Navigate to={`${RouteNames.adminAdmissionInstallments}/${installmentDetails.admission_id}/2`}/>;

    return (
        <>
            <DefaultStructure pageTitle="Installment Payment">
                <Activity/>
                <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col s10 m6 l6">
                                <h5 className="breadcrumbs-title mt-0 mb-0"><span>Installment Paymentss</span></h5>
                                <ol className="breadcrumbs mb-0">
                                    <li className="breadcrumb-item">
                                        <Link to="#">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to={RouteNames.adminAdmissionList}>Admissions</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        <Link to="#">Installment Payment</Link>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col s12">
                    <div className="container">
                        <div className="card">
                            <div className="card-content">
                                <div className="body">
                                    <div className="row">
                                        {
                                            parseInt(flag) === 0 ? <div className="row" style={{ borderBottom: '1px solid', 'margin': '15px 0' }}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <h6><strong>Payment Details</strong> </h6>
                                                    <p>Installment Amount - Rs. {installmentDetails?.installment_amount}</p>

                                                    <p>Careforward Amount - Rs. {installmentDetails?.careforward_amount_advance > 0 ? installmentDetails?.careforward_amount_advance : installmentDetails?.careforward_amount > 0 ? '-' + installmentDetails?.careforward_amount : installmentDetails?.careforward_amount}</p>

                                                    <p>Total Due Amount - Rs. {installmentDetails?.careforward_amount_advance > 0 ? installmentDetails?.installment_amount - installmentDetails?.careforward_amount_advance : parseInt(installmentDetails?.installment_amount) + parseInt(installmentDetails?.careforward_amount)}</p>
                                                    <p></p>
                                                    {/* <i style={{ paddingLeft: 5, cursor: 'pointer', color: '#00c853' }} onClick={handleAddPaymentDetails} className="material-icons">add_circle</i> */}
                                                </div>
                                            </div> : null
                                        }

                                        <div className="row">
                                            <div className='m4'>
                                                <TableContainer>
                                                    <Table aria-label="customized table">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Payment Type</TableCell>
                                                                <TableCell align="right">Number</TableCell>
                                                                <TableCell align="right">Date</TableCell>
                                                                <TableCell align="right">Bank Name</TableCell>
                                                                <TableCell align="right">Amount</TableCell>
                                                                <TableCell align="right">Action</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>

                                                            {
                                                                paymentDetails?.map((data) => {
                                                                    return (
                                                                        <TableRow
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                            key={data.id}
                                                                        >
                                                                            <TableCell component="th" scope="row">
                                                                                {paymentTypeCheck(parseInt(data.payment_type))}
                                                                            </TableCell>
                                                                            <TableCell align="right">{data.scp_number}</TableCell>
                                                                            <TableCell align="right">{moment(data.created_at).format('DD-MM-YYYY')}</TableCell>
                                                                            <TableCell align="right">{data.scp_bank}</TableCell>
                                                                            <TableCell align="right">{data.scp_amount}</TableCell>
                                                                            <TableCell align="right">{parseInt(data.payment_type) === 3 || parseInt(data.payment_type) === 4 ? <CheckBoxComp handelInstallmentCheck={handelInstallmentCheck} id={data.id} /> : null}</TableCell>
                                                                        </TableRow>
                                                                    )
                                                                })
                                                            }


                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="input-field col m6 s12">
                                                <TextField
                                                    id="cheque_return"
                                                    name='cheque_return'
                                                    label="Cheque Return Charge"
                                                    type='number'
                                                    onChange={(e) => setChequeReturnText(e.target.value)}
                                                    value={chequeReturnText}
                                                    margin="normal"
                                                    variant="standard"
                                                    style={{ width: '100%' }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            {/* <Link to={`${RouteNames.adminAdmissionInstallments}/${installmentDetails.admission_id}`} className="mb-6 btn waves-effect waves-light" style={{ 'margin': '0 15px' }}>Back</Link> */}
                                            <button className="btn cyan right cunclsubmit" type="submit" name="action" onClick={() => handelForm()}>Update
                                                <i className="material-icons right">send</i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DefaultStructure>
        </>
    )
}

export default ChequeReturn
