import { Checkbox } from '@mui/material'
import React from 'react'
import Swal from 'sweetalert2'
import {Helper} from "../../../helpers/helper";
function CheckBoxComp({ handelInstallmentCheck, id }) {
    const [checked, setChecked] = React.useState(false)
    const handelCheck = () => {
       
        if(!checked){
            Swal.fire({
                title: 'Are you sure?',
                text: "You want to cancel cheque",
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
              }).then((result) => {
                Helper.appLog('====================================');
                Helper.appLog(result);
                Helper.appLog('====================================');
                if (result.value) {
                    handelInstallmentCheck(!checked, id)
                    setChecked(!checked)
                }
              })
        }else{
            handelInstallmentCheck(!checked, id)
            setChecked(!checked)
        }

    }
    return (
        <Checkbox checked={checked}
            onChange={() => handelCheck()} />
    )
}

export default CheckBoxComp