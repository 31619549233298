import React, { useState } from 'react'
import { useDispatch,useSelector } from "react-redux";
import {hasPermission} from "../../../store/actions/rolesPermission"
import rolenames from "../../../constants/rolenames";
import {
    InputLabel, Button, IconButton, Select, TextField, MenuItem, Radio, RadioGroup, FormControlLabel,
    FormControl, FormLabel, Typography, ListItemText, Input, Checkbox, InputAdornment, Slider, Divider
  } from '@mui/material';
import {Helper} from "../../../helpers/helper";

const Item = ({ data,scholarShips,finalCourseFeeListChanger,courseFeesList }) => {
    const [ show, setShow ] = useState( false );
    const [ currentScholarship, setCurrentScholarship ] = useState( {} );
    const moreButtonHandler = (ev) =>{
        ev.preventDefault();
        setShow( prevState => !prevState );
    }
    if( !data )
        return (
            <tr>
                <td colSpan={3}>
                    <span className="name">No Data found</span>
                </td> 
            </tr>
        );
    const setScholarShipWithAmount = (fee,scholarship_id)=>{
        Helper.appLog(fee,scholarship_id)
        // Helper.appLog(courseFeesList)
        let allCourseFees = [...courseFeesList]
        let course = allCourseFees.find(data=>data.course_id==fee.course_id)
        // Helper.appLog(course)
        let fees = course.data.find(item=>item.id == fee.id)
        let scholarship = scholarShips.find(item=>item.id == scholarship_id)
        if(scholarship){
            setCurrentScholarship(prev=>scholarship)
        }
        fees.fees_amount = parseFloat(fees.fees_amount)
        fees.percent_amount = (scholarship.percentage/100)*(fees.fees_amount)
        Helper.appLog(parseFloat(fees.fees_amount),fees,scholarship.percentage,333)
        fees.amount = parseFloat(fees.fees_amount) - parseFloat(fees.percent_amount)
        finalCourseFeeListChanger(allCourseFees)

    }


    return (
        <tr>
            <td>
                <span className="name">{ data.fee_details.course_fee_name }</span>
            </td>
            <td>
                <span className="name">{ data.fee_details.scholarchip_applicable == 1 ? "Yes" : "No" }</span>
            </td>
            <td>
                <span className="email">{ data.fees_amount }</span>
            </td> 
            <td>
                <span className="name">{ data.fee_details.scholarchip_applicable == 1 ? <Select
                    variant="standard"
                    defaultValue={0}
                    label="Center"
                    labelId="selection"
                    style={{opacity:'0px'}}
                    name="center_id"
                    className="selectClass"
                    onChange={(e)=>{setScholarShipWithAmount(data,e.target.value)}}
                >
                    <MenuItem value={0}>
                    Scholarship
                    </MenuItem>
                        
                    {scholarShips.map(scholarShip => {
                    if(scholarShip.name != ''){
                        return(
                            <MenuItem value={scholarShip.id} name={scholarShip.name} key={scholarShip.id}>{scholarShip.name}</MenuItem>
                        )  
                    }
                    })}
                </Select> : "NA" } 
             </span>
            </td> 
            <td>
                <span className="role">{data.fee_details.scholarchip_applicable == 1 ?(currentScholarship.percentage ? currentScholarship.percentage+"%":"") : "NA"}</span>
            </td> 
            <td>
                {data.percent_amount?`${data.percent_amount}`:'NA'}
            </td>
            <td>
                {data.amount ? data.amount : data.amount==0?data.amount : data.fees_amount}
            </td>
    </tr>
    )
}

export default Item;
