import React, { useState } from 'react';
import { RouteNames } from '../../constants/RouteNames';
import DefaultStructure from '../../layouts/defaultStructure';
import { Link } from 'react-router-dom';
import Activity from '../counselling/components/activity';
import CourseSectionById from './Components/CourseSectionById';

function AdmissionDetailsUpdateByCourse() {
  //const [wavesAmount, setWavesAmount] = useState('')
  //const [remark, setRemark] = useState('')

  return (
    <>
      <DefaultStructure pageTitle="Admission Details">
        <Activity/>
        <div className="breadcrumbs-dark pb-0 pt-4" id="breadcrumbs-wrapper">
          <div className="container">
            <div className="row">
              <div className="col s10 m6 l6">
                <h5 className="breadcrumbs-title mt-0 mb-0"><span>Admission Details</span></h5>
                <ol className="breadcrumbs mb-0">
                  <li className="breadcrumb-item">
                    <Link to="#">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to={RouteNames.adminAdmissionList}>Admissions</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="#">Admission Update</Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="col s12">
          <div className="container">
            <div className="card">
              <div className="card-content">
                <div className="row card-row">
                  <CourseSectionById />
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultStructure>
    </>
  )
}

export default AdmissionDetailsUpdateByCourse
