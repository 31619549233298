import React from 'react';

function Footer(props) {
    return (
	    <footer className="page-footer footer footer-static footer-dark gradient-45deg-indigo-purple gradient-shadow navbar-border navbar-shadow">
	      <div className="footer-copyright">
	        <div className="container"><span>&copy; {new Date().getFullYear()} Pathfinder ERP</span></div>
	      </div>
	    </footer>
    )
}
export default Footer;