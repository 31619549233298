import HttpRequest from "./HttpRequest";
import {Helper} from "../../helpers/helper";
//=================States======================
export const getStates = async () => {
    try {
        const result = await (new HttpRequest()).getData('states/list');
        if (!result.error) {
            return result;
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Branch section==============
export const getBranchUsers = async () => {
    try {
        const result = await (new HttpRequest()).getData('branch/users');
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getZoneUsers = async () => {
    try {
        const result = await (new HttpRequest()).getData('zone/users');
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getBranchById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`branch/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addBranch = async (data) => {
    try {
        const result = await (new HttpRequest()).postData('branch/store', data);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Zone added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Zone can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Zone can not be added.'
        }
    }
}

export const updateBranch = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`branch/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Zone updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Zone can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Zone can not be updated.'
        }
    }
}

export const getBranches = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`branch/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Team section==============
export const getTeamLeadUsers = async () => {
    try {
        const result = await (new HttpRequest()).getData('team-lead/users');
        if (!result.error) {
            return result
        }
        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//==================== Team Section ==================
export const getTeamById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`team/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addTeam = async (data) => {
    try {
        const result = await (new HttpRequest()).postData('team/store', data);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Team added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Team can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Team can not be added.'
        }
    }
}

export const updateTeam = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`team/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Team updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Team can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Team can not be updated.'
        }
    }
}

export const getTeams = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`team/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//==================== Source Section ==================
export const getSourceById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`source/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addSource = async (data) => {
    try {
        const result = await (new HttpRequest()).postData('source/store', data);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Source added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Source can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Source can not be added.'
        }
    }
}

export const updateSource = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`source/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Source updated successfully.'
            }
        }

        if (result.redirect)
            return result;
        return {
            error: true,
            data: null,
            message: 'Source can not be updated.'
        }
    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Source can not be updated.'
        }
    }
}

export const getSource = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`source/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//=================Course section==============
export const getDepartments = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData('department/list', params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const getCourseById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getcourseByClassId = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course/list?class_id=${id}&limit=50`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addCourse = async (data) => {
    try {
        const result = await (new HttpRequest()).postDataWithFiles('course/store', data);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Course can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course can not be added.'
        }
    }
}

export const updateCourse = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postDataWithFiles(`course/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course can not be updated.'
        }
    }
}

export const getCourses = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`course/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const deleteCourse = async (id) => {
    try {
        const result = await (new HttpRequest()).postData(`delete-course`, {id})
        if (!result.error) {
            return result
        } else {
            throw new Error();
        }

    } catch (error) {
        throw new Error();
    }
}

export const removeCourseAttachmentById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course/attachment/destroy/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//=================Course fees section==============
export const getFees = async () => {
    try {
        const result = await (new HttpRequest()).getData('fee/list');
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getCourseFeeById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course-fee/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addCourseFee = async (data) => {
    try {
        const result = await (new HttpRequest()).postData('course-fee/store', data);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Course can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course can not be added.'
        }
    }
}

export const updateCourseFee = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`course-fee/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course fee updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Course fee can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course fee can not be updated.'
        }
    }
}

export const getCourseFees = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`course-fee/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const removeCourseFeeById = async (id) => {
    try {
        const result = await (new HttpRequest()).deleteData(`course-fee/destroy/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//==================== Source Section ==================
export const getSourceList = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`class/list`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getClasses = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`class/list`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const saveClassData = async (postData) => {
    try {

        const result = await (new HttpRequest()).postData(`class/store`, postData);

        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const updateClassData = async (id, postData) => {
    try {

        const result = await (new HttpRequest()).postData(`class/${id}`, postData);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Departmenet section==============

export const getDepartmenetById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`department/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addDepartmenet = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('department/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Departmenet added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Departmenet can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Departmenet can not be added.'
        }
    }
}

export const updateDepartmenet = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`department/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Departmenet updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Departmenet can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Departmenet can not be updated.'
        }
    }
}

export const getDepartmenet = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`department/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Center section==============

export const getCenterById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`center/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addCenter = async (data) => {
    Helper.appLog('888888888888888888888');
    Helper.appLog(data);
    try {

        const result = await (new HttpRequest()).postData('center/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Center added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Center can not be added.'
        }
    }
}

export const updateCenter = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`center/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Center updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Center can not be updated.'
        }
    }
}

export const getCenter = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`center/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Fee section==============

export const getFeeById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`fee/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addFee = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('fee/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Fee added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Fee can not be added.'
        }
    }
}

export const updateFee = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`fee/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Fee updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Fee can not be updated.'
        }
    }
}

export const getFee = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`fee/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//=================Scholarship section==============

export const getScholarshipById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`scholarship/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addScholarship = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('scholarship/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Scholarship added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Scholarship can not be added.'
        }
    }
}

export const updateScholarship = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`scholarship/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Scholarship updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Scholarship can not be updated.'
        }
    }
}

export const getScholarship = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`scholarship/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}


//=================Tellecaller section==============

export const getTellecallers = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`telecaller/users`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//=================lead section==============

export const getLeadById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`lead/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getLeadDetailsById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`lead/details/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getLeadHistoryById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`lead/get/histories/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}


export const addAssigntoLead = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('lead/assigne/save', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Lead  Assigned successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Lead can not be Assigned.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Lead can not be Assigned.'
        }
    }
}

export const addLead = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('lead/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Lead added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Lead can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Lead can not be added.'
        }
    }
}

export const updateLead = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`lead/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Lead updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Lead can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Lead can not be updated.'
        }
    }
}

export const getLead = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`lead/list`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const filterLead = async (data) => {
    try {

        const result = await (new HttpRequest()).getWithFilterData('lead/list', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {},
                message: 'Lead added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Lead can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Lead can not be added.'
        }
    }
}

export const importLeadData = async (params) => {
    try {
        //Helper.appLog(params);
        const result = await (new HttpRequest()).postDataWithFiles('lead/csv/import', params);
        if (!result.error)
            return result;
        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            result: null,
            message: error
        }
    }
}

export const leadBulkStatusUpdate = async (data) => {
    try {
        //Helper.appLog(params);
        const result = await (new HttpRequest()).postData('lead/bulk-status/update', data);
        if (!result.error)
            return result;
        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            result: null,
            message: error
        }
    }
}

export const getLeadDetailsByNumber = async (number) => {
    try {
        const result = await (new HttpRequest()).getData(`lead/search/bynumber/${number}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

        //throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Admission section==============

export const counsellingSearch = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`counselling/search/${id}`);
        if (!result.error) {
            return result
        }

        if (result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const courseFeesSearch = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('course-fee/search/bycourseid', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'course fee added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'course fee can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'course fee can not be added.'
        }
    }
}


export const getscholarshipList = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`scholarship/list`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const getcourseList = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`course/list`, params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const updateAddmissionCourseDetails = async (id, params) => {
    try {
        const result = await (new HttpRequest()).postData(`admission/student/course-details/${id}`, params);
        if (!result.error) {
            return result
        }
        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}


export const getCourseDetailsById = async (params) => {
    try {
        const result = await (new HttpRequest()).getData(`admission/student/course-details/${params}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getCourseDetailsByAdmissionAndCourseId = async (admissionId, courseId) => {
    try {
        const result = await (new HttpRequest()).getData(`admission/student/course-details/${admissionId}?course_id=${courseId}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getAdmissionIdBySearchKey = async (search) => {
    try {
        const result = await (new HttpRequest()).getData(`admission/get/admissionid/${search}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//==================== Course Topic Section ==================
export const getTopicByCourseSubjectId = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course-topic/list/bycourse-subject?cs_id=${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getCourseTopicById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course-topic/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addCourseTopic = async (data) => {
    try {
        const result = await (new HttpRequest()).postData('course-topic/store', data);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course topic added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course topic can not be added.'
        }
    }
}

export const updateCourseTopic = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`course-topic/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course topic updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course topic can not be updated.'
        }
    }
}

export const getCourseTopics = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`course-topic/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//==================== Course Sub Topic Section ==================
export const getcourseSubTopicByTopicId = async (id, center_id) => {
    try {
        const result = await (new HttpRequest()).getData(`course-sub-topic/bytopic/sub-topics?topic_id=${id}&center_id=${center_id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getCourseSubTopicById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course-sub-topic/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addCourseSubTopic = async (data) => {
    try {
        const result = await (new HttpRequest()).postData('course-sub-topic/store', data);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course sub topic added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course sub topic can not be added.'
        }
    }
}

export const updateCourseSubTopic = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`course-sub-topic/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course sub topic updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Course sub topic can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course sub topic can not be updated.'
        }
    }
}

export const getCourseSubTopics = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`course-sub-topic/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getCourseFeeSearchByCourseId = async (course_id) => {
    try {
        const result = await (new HttpRequest()).getData(`course-fee/search/bycourseid?course_id=` + course_id);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Subject section==============

export const getSubjectById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`subject/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addSubject = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('subject/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Subject added successfully.'
            }
        }


        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Subject can not be added.'
        }
    }
}

export const updateSubject = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`subject/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Subject updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: result.message
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Subject can not be updated.'
        }
    }
}

export const getSubjects = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`subject/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}


export const deleteSubjects = async (params) => {
    try {
        const result = await (new HttpRequest()).postData(`delete-subject`, {id: params});
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        } else {
            throw new Error(result.message);
        }
    } catch (error) {
        throw new Error();
    }
}

export const updateStudentSubject = async (params) => {
    const result = await (new HttpRequest()).postData(`student/update-subjects`, params);
    if (!result.error) {
        return {
            error: false,
            data: result.data,
            extra: result.extra ? result.extra : {}
        }
    } else {
        throw new Error(result.message);
    }
    //Helper.appLog(result.data);
}

//=================Section section==============

export const getSectionById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`section/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addSection = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('section/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Section added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Section can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Section can not be added.'
        }
    }
}

export const updateSection = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`section/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Section updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Section can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Section can not be updated.'
        }
    }
}

export const getSections = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`section/list`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Tag section==============

export const addTag = async (data) => {
    try {
        Helper.appLog('-------------vx--------')
        Helper.appLog(data)

        const result = await (new HttpRequest()).postData('tags/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Tag added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Tag can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Tag can not be added.'
        }
    }
}

export const getTagById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`tags/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getTags = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`tags/list`, params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const getAllTags = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`tags/list`, params);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const updateTag = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`tags/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Tag updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Tag can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Tag can not be updated.'
        }
    }
}

export const deleteTag = async (id) => {
    try {
        const result = await (new HttpRequest()).postData(`tags/destroy/${id}`)
        if (!result.error) {
            return result
        } else {
            throw new Error();
        }

    } catch (error) {
        throw new Error();
    }
}
//=================Board section==============

export const getBoardById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`board/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}


export const addBoard = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('board/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Board added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Board can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Board can not be added.'
        }
    }
}

export const updateBoard = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`board/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Board updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Board can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Board can not be updated.'
        }
    }
}

export const getBoards = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`board/list`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//=================Course Subject section==============

export const getCourseSubjectById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course-subject/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addCourseSubject = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('course-subject/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course subject added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Course subject can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course subject can not be added.'
        }
    }
}

export const updateCourseSubject = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`course-subject/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Course subject updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Course subject can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Course subject can not be updated.'
        }
    }
}

export const getCourseSubjects = async () => {
    try {
        const result = await (new HttpRequest()).getData(`course-subject/list`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const getSubjectByCourse = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`course-subject/bycourse/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//=================get students==============
export const getStudents = async (sparams) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData('students', sparams);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//========get teachers==============
export const getTeacherUsers = async () => {
    try {
        const result = await (new HttpRequest()).getData('faculty/users');
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//=================Announcement section==============

export const getAnnouncementById = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`announcement/${id}`);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

export const addAnnouncement = async (data) => {
    try {

        const result = await (new HttpRequest()).postData('announcement/store', data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Announcement added successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Announcement can not be added.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Announcement can not be added.'
        }
    }
}

export const updateAnnouncement = async (data, id) => {
    try {
        const result = await (new HttpRequest()).postData(`announcement/${id}`, data);

        if (!result.error) {
            return {
                error: false,
                data: result.data,
                message: 'Announcement updated successfully.'
            }
        }

        if (result.redirect)
            return result;

        return {
            error: true,
            data: null,
            message: 'Announcement can not be updated.'
        }

    } catch (error) {
        return {
            error: true,
            data: null,
            message: 'Announcement can not be updated.'
        }
    }
}

export const getAnnouncements = async (params) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`announcement/list`, params);
        if (!result.error) {
            return {
                error: false,
                data: result.data,
                extra: result.extra ? result.extra : {}
            }
        }

        if (result.redirect)
            return result;
        throw new Error(result.message);
    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}

//=================Additional Product Master==============//
export const getAllAdditionalProduct = async (params = {}) => {
    try {
        const result = await (new HttpRequest()).getWithFilterData(`additional-product/list`, params);
        if (!result.error) {
            return result;
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const getAdditionalProduct = async (id) => {
    try {
        const result = await (new HttpRequest()).getData(`additional-product/${id}`);
        if (!result.error) {
            return result;
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const saveAdditionalProduct = async (postData) => {
    try {

        const result = await (new HttpRequest()).postData(`additional-product/store`, postData);

        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
export const updateAdditionalProduct = async (id, postData) => {
    try {

        const result = await (new HttpRequest()).postData(`additional-product/${id}`, postData);
        if (!result.error) {
            return result
        }

        if (result.redirect)
            return result;

        throw new Error(result.message);

    } catch (error) {
        return {
            error: true,
            data: null,
            message: error
        }
    }
}
//=================Additional Product Master==============//
